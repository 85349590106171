// @flow
import Framework7 from 'framework7';
import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {Router} from "framework7/modules/router/router";


export const isProduction = () => (process.env.NODE_ENV === "production");

console.log('isProduction', isProduction() ? 't' : 'f');


// @ts-ignore
export const getInstance = (): Framework7 => (Framework7.instance);

export const getRouter = () => (getInstance().views.main.router);

export const setRouter = (url: string, options: Router.RouteOptions = {}) => (getInstance().views.main.router.navigate(url,options));

const isMobile = window.matchMedia("(max-width: 767px)").matches;


export function toastSuccess(text: string, closeTimeout: number = 2000) {
	getInstance().toast.show({
		text,
		closeButton: true,
		closeButtonText: 'Ok',
		closeButtonColor: 'white',
		//icon: icon,
		cssClass: 'success-toast',
		position: 'center',
		closeTimeout,
	});
}


export function toastError(text: string, closeTimeout: number = 5000) {
	getInstance().toast.show({
		text,
		closeButton: true,
		closeButtonText: 'Continua',
		closeButtonColor: 'white',
		//icon: icon,
		cssClass: 'alert-toast',
		position: 'center',
		closeTimeout,
	});
}


export function toastInfo(text: string, closeTimeout: number = 2000) {
	getInstance().toast.show({
		text,
		closeButton: true,
		closeButtonText: 'Ok',
		closeButtonColor: 'white',
		//icon: icon,
		cssClass: 'info-toast',
		position: 'center',
		closeTimeout,
	});
}


//import {AsyncSubject, Observable} from "rxjs";
//import {Map} from "rxjs/util/Map";
// Javascript
//import {Loading, LoadingController, LoadingOptions} from "ionic-angular";
//import {AgxStorageService} from "./storage";
//import {logError} from "./helpers";
//import {HttpClient, HttpEventType, HttpRequest, HttpResponse} from "@angular/common/http";
//import {last, tap} from "rxjs/operators";
//import "rxjs/add/operator/shareReplay";

interface webClientRequestConfig extends AxiosRequestConfig {
	debug?: boolean;
}


export async function webclient(url: string, data: any = {}, message: string | null = null, options: webClientRequestConfig = {}) {
	options.url = url;
	options.data = data;
	if (!options.method) {
		options.method = 'POST';
	}
	/*if (!options.withCredentials) {
		options.withCredentials = true;
	}*/
	if (!options.timeout) {
		options.timeout = 30000;
	}


	if (options.debug) {
		console.debug('[axios] request:', options.url, options.method);
	}

	if (message) {
		getInstance().dialog.preloader(message);
	}

	let res: AxiosResponse | null = null;
	try {
		res = await axios(options);
	} catch (err) {
		console.error('[axios] exception:', err, options);
		res = null;
	}

	if (message) {
		getInstance().dialog.close();
	}

	if (res === null || !res) {
		console.error('[axios] invalid response: ', res);
		return false;
	}

	if (res.status < 200 || res.status >= 300) {
		console.error('[axios] http error response: ' + res.status + ' ' + res.statusText, res);
		return false;
	}


	if (options.debug) {
		console.log('[axios] response:', res);
	}

	// return directly the res.data.data
	if (res.data && res.data.hasOwnProperty('data') && res.data.hasOwnProperty('error')) {
		if (res.data.error > 0) {
			console.error('[webclient] app error:', res.data.message);

			const position = isMobile ? 'bottom' : 'center';
			const icon = isMobile ? '<i class="f7-icons">alert</i>' : undefined;
			getInstance().toast.show({
				text: res.data.message,
				closeButton: true,
				closeButtonText: 'Ok',
				closeButtonColor: 'white',
				icon: icon,
				cssClass: 'alert-toast ',
				position: position,
				closeTimeout: 5000,
			});
			return false;
		}
		// return value
		return res.data.data;
	}

	// return whole response

	if (options.debug) {
		console.warn('- returning raw response');
	}
	return res.data;
}


/*
@Injectable()
export class AgxWebClientService {

	/**
	 * ttlCache is an in-memory cache for repeated requests, only ID and TimeStamp are stored
	 *
	private ttlCache: Map<string,number>;

	public loaderOptions: LoadingOptions;

	constructor(public http: HttpClient, public LoadingController: LoadingController, public storage: AgxStorageService) {
		this.ttlCache = new Map();
		this.loaderOptions = {} as LoadingOptions;
	}

	private static getTime(): number {
		return Math.floor(new Date().getTime() / 1000);
	}


	/**
	 * perform an HTTP request leveraging localForage for cache and fallback values
	 * @param req            the angular http's RequestMethod to use {method + url}
	 * @param options
	 *
	public request(req: any, options: AgxWebClientOptions = {}): AsyncSubject<any> {

		if (options.id) {
			if (!options.postCache) {
				options.postCache = true;
			}
			if (!options.preCache) {
				options.preCache = false;
			}
		} else {
			options.id = null;
			options.preCache = false;
			options.postCache = false;
		}
		if (!options.timeout) {
			options.timeout= 60000; // 60 sec
		}
		if (!options.ttlSeconds) {
			options.ttlSeconds = 60;
		}
		//if (!options.isJson) options.isJson = true;
		if (!options.debug) {
			options.debug = false;
		}
		if (!options.loader) {
			options.loader = false;
		}
		if (options.loader) {
			if (!options.loaderOptions) {
				options.loaderOptions = {} as LoadingOptions;
			}
			if (options.message) {
				options.loaderOptions.content = options.message;
			}
			// Merge Defaults and Current
			options.loaderOptions = Object.assign(this.loaderOptions, options.loaderOptions);
		}

		if (options.debug) {
			console.debug('request:', options.id ? options.id : req.url);
		}

		let observer = new AsyncSubject;

		// preCache will always skip the HTTP request if present
		if (options.preCache) {

			if (this.ttlCache.has(options.id)) {
				let ttl: number = this.ttlCache.get(options.id) + 0;
				if (options.debug) {
					$log.debug('ttl', ttl);
				}
				let ts: number = AgxWebClientService.getTime();
				let delta = ts - ttl;
				if (options.debug) {
					$log.debug('ts', ts);
				}
				if (options.debug) {
					$log.debug('delta', delta);
				}

				// verify TTL
				if (options.ttlSeconds > 0 && delta > options.ttlSeconds) {
					$log.warn('cache is expired for id: ' + options.id, options.ttlSeconds);
					options.preCache = false;
				}

			} else {
				if (options.debug) {
					$log.warn('preCache [NOT FOUND] for: ' + options.id);
				}
				options.preCache = false;
			}
		}

		// is preCache still valid?
		if (options.preCache) {

			this.storage.get(options.id)
				.then(val => {
					if (options.debug) {
						$log.debug('preCache [FOUND] for: ' + options.id, val);
					} else {
						$log.debug('preCache [FOUND] for: ' + options.id);
					}
					observer.next(val);
					observer.complete();
				})
				.catch( () => {
					if (options.debug) {
						$log.warn('preCache [NOT FOUND] for: ' + options.id + '. Doing subrequest...');
					}
					return this.subRequest(observer, req, options);
				})
			;

			if (options.debug) {
				$log.debug('returning empty subject for id:', options.id);
			}
			return observer;
		}
		// else
		if (options.debug) {
			$log.debug('Non cached request. Doing subrequest for id:', options.id);
		}
		return this.subRequest(observer, req, options);
	}


	private subRequest(observer: AsyncSubject<any>, req: any, subOptions: AgxWebClientOptions): AsyncSubject<any> {

		// Show Loader if running HTTP request
		let loader: Loading = null;
		let messaggioIniziale = '';
		if (subOptions.loader) {
			messaggioIniziale = '' + subOptions.loaderOptions.content;
			loader = this.LoadingController.create(subOptions.loaderOptions);
			loader.present().catch(logError);
		}

		if (subOptions.debug) {
			console.debug('subRequest....', subOptions.id);
		}

		//let extra = { reportProgress: false };
		let extra = {};
		if (subOptions.progress) {
			if (subOptions.debug) {
				console.warn('enabling progress for:', subOptions.id);
			}
			extra = { reportProgress: true };
		}


		let options = new HttpRequest(req.method,req.url, req.body, extra);


		let hReq = this.http.request(options)
			//TODO .timeout(10000, new Error('timeout exceeded'))
			.timeout(subOptions.timeout)
		;

		if (true|| subOptions.progress) {
			hReq = hReq.pipe(
				/*map(event => {
					console.log('MAP EVENT', event);
					return event;
				}),*
				tap(event => {
					//console.log('TAP', event);
					if (event.type==HttpEventType.UploadProgress || event.type==HttpEventType.DownloadProgress) {
						//console.log('PROGRESS', event);
						let mbf = event.loaded / (1024*1024);
						let mbs = mbf.toFixed(1);

						if (loader) {
							let msg = messaggioIniziale + ' ... '+ mbs.toString()+' MB';
							loader.setContent(msg);
						}
					}
				}),
				last(), // return last (completed) message to caller
				//catchError(this.handleError(file))
			);
		}

		hReq
			.map((res: HttpResponse<any>) => res.body)
			.shareReplay()
			.subscribe(
				(value: any) => {
					if (subOptions.debug) {
						$log.debug('http value for id:' + subOptions.id, value);
					}

					// STORE
					if (subOptions.postCache) {

						this.storage.setSafe(subOptions.id, value)
							.then(res => {
								if (res) {
									$log.debug('postCache [SAVE OK] for', subOptions.id);
									// Save for TTL preCache
									this.ttlCache.set(subOptions.id, AgxWebClientService.getTime());
								} else {
									$log.error('postCache [SAVE FAIL] for ' + subOptions.id);
								}
								if (loader) {
									loader.dismiss().catch(logError);
								}
								observer.next(value);
								observer.complete();
							})
						;

					} else {
						if (loader) {
							loader.dismiss().catch(logError);
						}
						observer.next(value);
						observer.complete();
					}
				},
				(error: any) => {
					let id:string = subOptions.id ? subOptions.id : req.url;
					$log.error('webclient request error for: ' + id, error);

					// LOAD FROM LOCALFORAGE
					if (subOptions.postCache) {

						this.storage.getSafe(subOptions.id, null)
							.then(val => {
								if (loader) {
									loader.dismiss().catch(logError);
								}
								if (val === null) {
									$log.warn('offline postCache [NOTFOUND] ' + subOptions.id, val);
									if (subOptions.debug) {
										$log.debug('rejecting for: ' + subOptions.id);
									}
									observer.error(error.message || error);
								} else {
									$log.debug('offline postCache [FOUND] ' + subOptions.id);
									if (subOptions.debug) {
										$log.debug('resolving for: ' + subOptions.id, val);
									}
									observer.next(val);
								}
								observer.complete();
							})
						;

					} else {
						if (loader) {
							loader.dismiss().catch(logError);
						}
						observer.error(error.message || error);
						observer.complete();
					}
				},
				() => {
					if (subOptions.debug) {
						$log.debug('subrequest http completed:', subOptions.id);
					}
				}
			);
		return observer;

	}


	// TEST: map ritorna un Observable
	public requestEx(req: any, options: AgxWebClientOptions): Observable<any> {

		if (options.debug) {
			$log.debug('requestEx:', options.id);
		}

		return this.request(req, options)
			.map(x => {
				if (options.debug) {
					console.debug('requestEx-map:', options.id);
				}
				if (!x.hasOwnProperty('error')) {
					throw new Error('invalid JSON format (error) for ' + options.id);
				}
				if (!x.hasOwnProperty('data')) {
					throw new Error('invalid JSON format (data) for ' + options.id);
				}
				if (!x.hasOwnProperty('message')) {
					throw new Error('invalid JSON format (message) for ' + options.id);
				}
				if (x.error>0) {
					if (options.debug) {
						console.error('requestEx response:', x);
					}
					if (x.message.length>0) {
						throw new Error(x.message);
					}
					throw new Error('requestEx error: ' + options.id);
				}
				// OK
				if (options.debug) {
								$log.debug('requestEx OK:', options.id);
				}
				return x.data;
						}
					}
				}
				if (options.debug) {
					$log.error('requestEx ERROR:', options.id);
				}
				// Error
				if (x.hasOwnProperty('message')) {
					throw new Error(x.message);
				}
				throw new Error('invalid JSON format for ' + options.id);
			})
			.do(x => {
				if (options.debug) {
					$log.debug('request-ex-do:', x);
				}
			});
	}

}

*/
