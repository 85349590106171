// @flow

import {Store} from 'laco';
import {Storage} from 'badpenguin-cordova-native';
import {getInstance, toastError, toastSuccess, webclient} from './f7agx';
import {logError} from './general';
import {getAuth} from './auth';
import {forceNumber} from "./validate";
import {Md5} from "ts-md5";
import {config} from "../app/config";


type IPedroniEvento = {
	id: number;
	title: string;
}


type IPedroniCitta = {
	title: string;
}


type IPedroniUtente = {
	utenti_id: number;
	nominativo: string;
	emailkey: string;
}


type IPedroniIscritto = {
	event_id: number;
	relatore_id: number;
	nome: string;
	cognome: string;
	citta: string;
	email: string;
	nascita: string;
	sesso: string;
	is_rivendita: boolean;
	privacy1: string;
	privacy2: string;
	privacy3: string;
	privacy4: string;
	privacy5: string;
	privacy6: string;
	firma: string;
}


type IPedroniPartecipante = {
	event_id: number;
	relatore_id: number;
	utenti_id: number;
	nominativo: string;
}

type IPedroniStoreEventoSelezionato = {
	eventoSelezionatoName: string;
	eventoSelezionatoId: number | null;
}
export type IPedroniStore = {
	ready: boolean;
	eventoSelezionatoName: string;
	eventoSelezionatoId: number | null;
	numeroEventiInElenco: number;
	elencoEventi: Array<IPedroniEvento>;
	numeroUtentiInElenco: number;
	elencoCitta: Array<IPedroniCitta>;
	numeroCittaInElenco: number;
	elencoUtenti: Array<IPedroniUtente>;
	numeroIscrittiInCoda: number;
	elencoIscritti: Array<IPedroniIscritto>;
	numeroPartecipantiInCoda: number;
	elencoPartecipanti: Array<IPedroniPartecipante>;
}


export const PedroniStore = new Store({
	ready: false,
	eventoSelezionatoName: 'Nessuno',
	eventoSelezionatoId: null,
	numeroEventiInElenco: 0,
	elencoEventi: [],
	numeroUtentiInElenco: 0,
	elencoUtenti: [],
	numeroIscrittiInCoda: 0,
	elencoIscritti: [],
	numeroPartecipantiInCoda: 0,
	elencoPartecipanti: [],
});


export const PedroniStoreSelezionaEventoById = (id: number): void => {
	let {elencoEventi}: { elencoEventi: Array<IPedroniEvento> } = PedroniStore.get();
	//console.log('- cerco evento da selezionare:', id);
	for (let i = 0; i < elencoEventi.length; i++) {
		if (elencoEventi[i].id === id) {
			PedroniStore.set(() => ({
				eventoSelezionatoId: elencoEventi[i].id,
				eventoSelezionatoName: elencoEventi[i].title
			}));
			Storage.set('evento-selezionato', elencoEventi[i].id).catch(logError);
			//console.log('- trovato', elencoEventi[i].id);
			return;
		}
	}
	console.warn('PedroniStoreSelezionaEventoById non trovato', id);
	PedroniStore.set(() => ({
		eventoSelezionatoId: null,
		eventoSelezionatoName: 'Nessuno'
	} as IPedroniStoreEventoSelezionato));
};


export const PedroniStoreLoadAllFromDevice = async (): Promise<void> => {
	console.log('PedroniStoreLoadAllFromDevice()');

	let elencoEventi: Array<IPedroniEvento> = await Storage.get('eventi-elenco', []);
	if (elencoEventi.length === 0) {
		// Leggi da internet
		console.warn('- elencoEventi vuoto, aggiorno dalla rete...');
		elencoEventi = await ajaxGetElencoEventi();
	}
	console.log('- elencoEventi', elencoEventi.length);
	PedroniStoreSetElencoEventi(elencoEventi);

	let elencoCitta: Array<IPedroniCitta> = await Storage.get('citta-elenco', []);
	if (elencoCitta.length === 0) {
		// Leggi da internet
		console.warn('- elencoCitta vuoto, aggiorno dalla rete...');
		elencoCitta = await ajaxGetElencoCitta();
	}
	console.log('- elencoCitta', elencoCitta.length);
	PedroniStoreSetElencoCitta(elencoCitta);

	let elencoUtenti: Array<IPedroniUtente> = await Storage.get('utenti-elenco', []);
	if (elencoUtenti.length === 0) {
		// Leggi da internet
		console.warn('- elencoUtenti vuoto, aggiorno dalla rete...');
		elencoUtenti = await ajaxGetElencoUtenti();
	}
	console.log('- elencoUtenti', elencoUtenti.length);
	PedroniStoreSetElencoUtenti(elencoUtenti);

	const elencoIscritti: Array<IPedroniIscritto> = await Storage.get('iscritti-elenco', []);
	console.log('- elencoIscritti', elencoIscritti.length);
	PedroniStore.set(() => ({
		elencoIscritti,
		numeroIscrittiInCoda: elencoIscritti.length
	}));

	const elencoPartecipanti: Array<IPedroniPartecipante> = await Storage.get('partecipanti-elenco', []);
	console.log('- elencoPartecipanti', elencoPartecipanti.length);
	PedroniStore.set(() => ({
		elencoPartecipanti,
		numeroPartecipantiInCoda: elencoPartecipanti.length
	}));

	const eventoSelezionatoId: number = await Storage.get('evento-selezionato', null);
	// TODO: convert to integer
	console.log('- eventoSelezionatoId', eventoSelezionatoId);
	PedroniStoreSelezionaEventoById(eventoSelezionatoId);

	// Set Ready
	PedroniStore.set(() => ({
		ready: true
	}));

	console.log('- PedroniStoreLoadAllFromDevice done');

};


async function ajaxGetElencoEventi(): Promise<Array<IPedroniEvento>> {
	const res = await webclient(config.endpoint + '/ajax/ihostess/eventi/elenco/', null, 'Aggiorno elenco eventi...');
	if (res === false || !Array.isArray(res)) {
		console.error('ajaxGetElencoEventi failed');
		return [];
	}
	return res;
}


async function ajaxGetElencoCitta(): Promise<Array<IPedroniCitta>> {
	const res = await webclient(config.endpoint + '/ajax/ihostess/citta/elenco/', null, 'Aggiorno elenco città...');
	if (res === false || !Array.isArray(res)) {
		console.error('ajaxGetElencoCitta failed');
		return [];
	}
	return res;
}


async function ajaxGetElencoUtenti(): Promise<Array<IPedroniUtente>> {
	const res = await webclient(config.endpoint + '/ajax/ihostess/utenti/elenco/', null, 'Aggiorno elenco utenti...');
	if (res === false || !Array.isArray(res)) {
		console.error('ajaxGetElencoUtenti failed');
		return [];
	}
	return res;
}


export const PedroniStoreLoadAllFromInternet = async (): Promise<void> => {
	console.log('PedroniStoreLoadAllFromInternet()');

	let elencoEventi: Array<IPedroniEvento> = await ajaxGetElencoEventi();
	if (elencoEventi) {
		PedroniStoreSetElencoEventi(elencoEventi);
	}

	let elencoCitta: Array<IPedroniCitta> = await ajaxGetElencoCitta();
	if (elencoCitta) {
		PedroniStoreSetElencoCitta(elencoCitta);
	}

	let elencoUtenti: Array<IPedroniUtente> = await ajaxGetElencoUtenti();
	if (elencoUtenti) {
		PedroniStoreSetElencoUtenti(elencoUtenti);
	}

	console.log('- PedroniStoreLoadAllFromInternet done');
};


function PedroniStoreSetElencoEventi(elencoEventi: Array<IPedroniEvento>): void {
	let r: Array<IPedroniEvento> = [];

	elencoEventi.forEach((src: IPedroniEvento) => {
		let id: number = forceNumber(src.id);
		if (id <= 0) {
			return;
		}
		if (!src.hasOwnProperty('title')) {
			return;
		}
		r.push({
			id,
			title: '' + src.title
		});
	});
	console.log('- storing elencoEventi', r.length);
	Storage.set('eventi-elenco', r).catch(logError);
	PedroniStore.set(() => ({
		elencoEventi: r,
		numeroEventiInElenco: r.length
	}));
}


function PedroniStoreSetElencoCitta(elencoCitta: Array<IPedroniCitta>): void {
	let r: Array<IPedroniCitta> = [];

	elencoCitta.forEach((src: IPedroniCitta) => {
		if (!src.hasOwnProperty('title')) {
			return;
		}
		r.push({
			title: '' + src.title
		});
	});
	console.log('- storing elencoCitta', r.length);
	Storage.set('citta-elenco', r).catch(logError);
	PedroniStore.set(() => ({
		elencoCitta: r,
		numeroCittaInElenco: r.length
	}));
}


function PedroniStoreSetElencoUtenti(elencoUtenti: Array<IPedroniUtente>): void {
	let r: Array<IPedroniUtente> = [];

	elencoUtenti.forEach((src: IPedroniUtente) => {
		let utenti_id: number = forceNumber(src.utenti_id);
		if (utenti_id <= 0) {
			return;
		}
		if (!src.hasOwnProperty('nominativo')) {
			return;
		}
		if (!src.hasOwnProperty('emailkey')) {
			return;
		}
		r.push({
			utenti_id,
			nominativo: '' + src.nominativo,
			emailkey: '' + src.emailkey
		});
	});
	console.log('- storing elencoUtenti', r.length);
	Storage.set('utenti-elenco', r).catch(logError);
	PedroniStore.set(() => ({
		elencoUtenti: r,
		numeroUtentiInElenco: r.length
	}));

}


export async function setElencoIscritti(elenco: Array<IPedroniIscritto>, background: boolean = true): Promise<void> {

	PedroniStore.set(() => ({
		elencoIscritti: elenco,
		numeroIscrittiInCoda: elenco.length
	}));

	// Background
	const promise = Storage.set('iscritti-elenco', elenco)
		.catch(logError);
	if (!background) {
		await promise;
	}
}

export async function cancellaIscritto(user: IPedroniIscritto, background: boolean = true) {
	const state: IPedroniStore = PedroniStore.get();
	let elenco: Array<IPedroniIscritto> = state.elencoIscritti.filter(el => el.event_id !== user.event_id || el.email !== user.email);
	 const promise = setElencoIscritti(elenco,background);
	if (!background) {
		await promise;
	}
}


export function aggiungiIscritto(user: IPedroniIscritto) {
	// Normalize Values
	//console.log('aggiungiIscritto', user);

	const pedroniState = PedroniStore.get();

	// Aggiunge Evento
	user.event_id = pedroniState.eventoSelezionatoId;

	// Aggiungi Relatore
	user.relatore_id = getAuth();

	// Aggiorna stato
	pedroniState.elencoIscritti.push(user);

	setElencoIscritti(pedroniState.elencoIscritti, true)
		.catch(logError);
}


export function cercaEmail(email: string): IPedroniUtente | undefined {
	let email5: string = Md5.hashStr(email).toString();

	const pedroniState: IPedroniStore = PedroniStore.get();
	return pedroniState.elencoUtenti.find((el) => {
		return (el.emailkey === email5)
	});
}


export function cercaUtentiId(id: number): IPedroniUtente | undefined {
	if (id < 1) return undefined;

	const pedroniState: IPedroniStore = PedroniStore.get();
	return pedroniState.elencoUtenti.find((el) => {
		return (el.utenti_id === id)
	});
}


export async function setElencoPartecipanti(elenco: Array<IPedroniPartecipante>, background: boolean = true): Promise<void> {

	PedroniStore.set(() => ({
		elencoPartecipanti: elenco,
		numeroPartecipantiInCoda: elenco.length
	}));

	// Background
	const promise = Storage.set('partecipanti-elenco', elenco)
		.catch(logError);
	if (!background) {
		await promise;
	}
}

export async function cancellaPartecipante(user: IPedroniPartecipante, background: boolean = true) {
	const state: IPedroniStore = PedroniStore.get();
	let elenco: Array<IPedroniPartecipante> = state.elencoPartecipanti.filter(el => el.event_id !== user.event_id || el.utenti_id !== user.utenti_id);
	const promise = setElencoPartecipanti(elenco,background);
	if (!background) {
		await promise;
	}
}


export function aggiungiPartecipante(user: IPedroniUtente) {
	// Normalize Values
	//console.log('aggiungiPartecipante', user);

	const pedroniState = PedroniStore.get();

	const partecipante: IPedroniPartecipante = {
		event_id: pedroniState.eventoSelezionatoId,
		relatore_id: getAuth(),
		utenti_id: user.utenti_id,
		nominativo: user.nominativo
	};

	/*
	// Aggiorna stato
	pedroniState.elencoPartecipanti.push(partecipante);
	pedroniState.numeroPartecipantiInCoda = pedroniState.elencoPartecipanti.length;

	// Background
	Storage.set('partecipanti-elenco', pedroniState.elencoPartecipanti)
		.catch(logError);
	*/
	pedroniState.elencoPartecipanti.push(partecipante);
	setElencoPartecipanti(pedroniState.elencoPartecipanti);
}


export async function inviaDati() {
	const pedroniState: IPedroniStore = PedroniStore.get();
	let dialog;
	let hasError: boolean = false;

	if (pedroniState.elencoPartecipanti.length > 0) {
		dialog = getInstance().dialog.progress('Invio partecipanti');
		dialog.setText('Invio di ' + pedroniState.numeroPartecipantiInCoda + ' partecipanti ...');

		// INVIO
		const res = await webclient(config.endpoint + '/ajax/ihostess/partecipanti/push/', {items: pedroniState.elencoPartecipanti});

		// AGGIORNA STATE
		if (res) {
			setElencoPartecipanti([]);
		}

		dialog.close();
		if (!res) {
			hasError = true;
			toastError('Invio partecipanti fallito');
		}

	}

	if (pedroniState.elencoIscritti.length > 0) {
		let conta = 0;
		let max = pedroniState.elencoIscritti.length;
		let progress: number = 0;
		dialog = getInstance().dialog.progress('Invio iscritti', progress);

		// Copia elenco
		let elenco: Array<IPedroniIscritto> = [...pedroniState.elencoIscritti];

		for (let iscritto of elenco) {
			conta++;
			progress = conta / max * 100;
			dialog.setText('Invio iscritto ' + conta + '/' + max + ' ...');
			dialog.setProgress(progress);

			// INVIO
			let utente: IPedroniIscritto = Object.assign({}, iscritto);
			//console.log('invio iscritto', utente);
			const res = await webclient(config.endpoint + '/ajax/ihostess/iscritti/push/', {item: utente});

			// AGGIORNA STATE
			if (res) {
				await cancellaIscritto(iscritto, false);
			}

			if (!res) {
				hasError = true;
				toastError('Invio iscritto fallito');
			}
		}

		dialog.close();
	}

	// Finito
	if (!hasError) {
		toastSuccess('Invio dati terminato');
	}

}
