// @flow

import {Store} from 'laco';


export const ServiceWorkerStore = new Store({
	ready: false,
	update: false,
	beforeinstallprompt: null,
	appinstalled: false
});


window.addEventListener('appinstalled', () => {
	console.log('[SWC] appinstalled');
	ServiceWorkerStore.set(() => ({appinstalled: true}));
});


window.addEventListener('beforeinstallprompt', (e) => {
	console.log('[SWC] beforeinstallprompt');
	ServiceWorkerStore.set(() => ({beforeinstallprompt: e}));
});


let currentRegistration: ServiceWorkerRegistration;


export function ServiceWorkerSkipWaiting() {
	if (currentRegistration && currentRegistration.waiting) {
		console.log('[SWC] sending SkipWaiting message');
		currentRegistration.waiting.postMessage({type: 'SKIP_WAITING'});
	} else {
		console.warn('[SWC] no instance waiting');
	}
}


export async function ServiceWorkerCheckUpdate(/*cb:Function=null*/): Promise<boolean> {

	if (!currentRegistration) {
		console.warn('[SWC] no registration available');
		return false;
	}

	console.log('[SWC] checking for SW update...');
	try {
		let found: boolean = false;
		// @ts-ignore
		let res: ServiceWorkerRegistration = await currentRegistration.update();
		if (res.installing) {
			console.log('[SWC] checking completed, SW is installing...');
			found = true;
		} else {
			console.log('[SWC] checking completed, is waiting?', res.waiting ? 'yes' : 'no');
		}
		if (res.waiting) {
			found = true;
			// Chiede se aggiornare via callback
			triggerNewServiceWorker();
		}
		return found;
	} catch (err) {
		console.error('[SWC] update error', err);
	}
	// No update available
	return false;
}


let callbackOnUpdateAvailable: Function | null = null;

export function ServiceWorkerSetOnUpdateAvailable(cb: Function) {
	callbackOnUpdateAvailable = cb;
}


function triggerNewServiceWorker() {

	//console.warn('SW is waitingm sending message');
	ServiceWorkerStore.set(() => ({update: true}));

	if (callbackOnUpdateAvailable) {
		console.log('[SWC] executing callback');
		callbackOnUpdateAvailable();
	} else {
		console.warn('no callbackOnUpdateAvailable');
	}
}


if ('serviceWorker' in navigator) {

	navigator.serviceWorker.ready.then((reg: ServiceWorkerRegistration) => {

		console.log('[SWC] ready');

		currentRegistration = reg;

		ServiceWorkerStore.set(() => ({ready: true}));

		if (reg && reg.active) {
			reg.active.addEventListener('statechange', (/*ev3:ServiceWorkerRegistration*/) => {
				console.log('[SWC] old state:', reg!.active!.state);
			});
		} else {
			console.error('reg is null');
		}


		if (reg.waiting !== null) {
			console.warn('[SWC] a new SW is waiting ...');
			triggerNewServiceWorker();
		}

		reg.addEventListener('updatefound', () => {
			console.log('[SWC] updatefound');
			const newWorker = reg.installing;

			if (!newWorker) {
				console.error('newWorker is null');
				return;
			}

			console.log('[SW] newWorker state is', newWorker.state);

			newWorker.addEventListener('statechange', (/*ev2*/) => {

				// Has service worker state changed?
				switch (newWorker.state) {
					case 'installed':

						console.log('[SWC] new SW installed!');
						triggerNewServiceWorker();

						break;

					case 'activated':

						console.log('[SWC] new SW activated, reloading!');
						setTimeout(() => {
							window.location.reload();
						}, 1000);

						break;

					default:
						console.log('[SWC] new SW state:', newWorker.state);
					// "installing" | "installed" | "activating" | "activated" | "redundant";

				}
			});

		});

	});


} else {
	console.error('[SWC] ServiceWorker Unavailable');
}


export async function ServiceWorkerAddToHome(): Promise<boolean> {
	const {beforeinstallprompt} = ServiceWorkerStore.get();
	if (!beforeinstallprompt) {
		console.error('ServiceWorkerAddToHome called with null prompt');
		return false;
	}
	beforeinstallprompt.prompt();
	const choiceResult = await beforeinstallprompt.userChoice;
	if (choiceResult.outcome !== 'accepted') {
		console.warn('ServiceWorkerAddToHome rejected by the user');
		return false;
	}
	console.log('ServiceWorkerAddToHome completed');
	ServiceWorkerStore.set(() => ({beforeinstallprompt: null, appinstalled: true}));
	return true;
}


export function ServiceWorkerIsAppStandalone(): boolean {
	if (window.matchMedia('(display-mode: standalone)').matches) {
		return true;
	}
	// @ts-ignore
	if (window.navigator.standalone === true) {
		return true;
	}
	return false;
}


export function ServiceWorkerUninstall() {
	if ('serviceWorker' in navigator) {
		navigator.serviceWorker.getRegistrations().then(function(registrations) {
			for(let registration of registrations) {
				console.error('[SWC] uninstalling');
				registration.unregister()

			}}).catch(function(err) {
				console.error('[SWC] failed to unregister: ', err);
		});
	} else {
		console.error('[SWC] not available');
	}
}
