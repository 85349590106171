import React from "react";

export function gaErrorHandler() {

	window.onerror = function (msg, url, line, col, error) {
		//console.error('[GTAG]', error);
		//connsole.debug('--- detail', msg, url, line, col);

		let detail: string = '';
		if (error && error.hasOwnProperty('stack')) {
			detail = (error as Error).stack!;
		}

		const message = msg+ ' at '+ url + ':' + line + ':' + col;

		//GaSendError(msg as string, url + ':' + line + ':' + col, true);
		GaSendError(message, detail, true);

	};

	//GaSendError('test', 'boot', false);
}


function GaSendError(message: string, detail: string, fatal: boolean) {

	if ("gtag" in window) {

		message = ''+ message.substr(0,140);
		detail  = ''+ detail.substr(0,450);

		console.log('[GTAG] sending error', message, detail);

		// @ts-ignore
		window.gtag('event', 'error', {
			'event_category': message,
			'event_label': detail,
			'non_interaction': true
		});

		/*window.gtag('event', 'exception', {
			'exDescription': message + ' at ' + detail,
			'exFatal': fatal
		});*/

	} else {
		console.warn('[GTAG] not available');
	}

}


export class GaErrorHandler extends React.Component {

	componentDidCatch(error: any, info: any) {
		// TODO: non invia le info
		//GaSendError(error.message, info.componentStack.toString(), false);
	}

	static getDerivedStateFromError() {
		return {};
	}

	render() {
		return this.props.children
	}
}
