// @flow strict

import React, {useEffect, useRef, useState} from 'react';
import {
	Button,
	List,
	ListButton,
	ListInput,
	ListItem,
	Navbar,
	NavLeft,
	NavRight,
	NavTitle,
	Page,
	Toolbar
} from 'framework7-react';
import SignaturePad from 'signature_pad';
// @ts-ignore
import {useStore} from 'laco-react';
import {aggiungiIscritto, IPedroniStore, PedroniStore} from '../laco/pedroni';
import {getInstance, isProduction, toastSuccess} from '../laco/f7agx';
import VisualizzaCondizioniPopup from '../components/VisualizzaCondizioniPopup';
import VisualizzaInformativaPopup from '../components/VisualizzaInformativaPopup';
import './FirmaPage.scss';
import PrivacyChoice from '../components/PrivacyChoice';
import ValidatedForm from '../components/ValidatedForm';
import {has18Years} from '../laco/date';
import {setDomChecked, setDomValue} from "../laco/vanilla";
import {config} from "../app/config";
import DataDiNascita from "../components/DataDiNascita";
import {validateEmail} from "../laco/validate";


export const FirmaPage = () => {

	//console.log('- FirmaPage render()');

	const pedroniState: IPedroniStore = useStore(PedroniStore);

	/*
	 * Validate at every refresh
	 */

	// Refs
	const signatureRef = useRef<HTMLCanvasElement>(null!);
	const formRef = useRef<HTMLFormElement>(null!);
	//const dateRef = useRef<HTMLInputElement>(null!);

	// Sate
	const [firmaInstance, setFirmaInstance] = useState<SignaturePad | null>(null);
	const [isFormValid, setFormValid] = useState<boolean>(false);
	//const setForceUpdate = useForceUpdate();

	useEffect(() => {
		console.log('=== FirmaPage Mount === ');

		//noinspection JSUnusedLocalSymbols
		//const autocompleteCitta =
		getInstance().autocomplete.create({
			inputEl: 'input[name="citta"]',
			openIn: 'dropdown',
			dropdownPlaceholderText: 'Digita la città dove vivi',
			typeahead: true,
			limit: 10,
			source: function (query, render) {
				let results: Array<string> = [];
				if (query.length === 0) {
					render(results);
					return;
				}
				// Find matched items
				const q2 = query.toLowerCase();
				let limit = 10;
				for (let i = 0; i < pedroniState.elencoCitta.length; i++) {
					const title = pedroniState.elencoCitta[i].title;
					if (title.toLowerCase().indexOf(q2) === 0) {
						results.push(title);
						limit--;
						if (limit < 0) {
							break;
						}
					}
				}
				render(results);
			},
			on: {
				closed: () => {
					checkCitta();

					const email = document.querySelector<HTMLInputElement>('input[name="email"]');
					if (email) {
						email.focus();
					}

				}
			}
		});


		resizeCanvas(/*null*/);
		let signaturePadInstance: SignaturePad;
		if (signatureRef.current) {
			signaturePadInstance = new SignaturePad(signatureRef.current, {
				//@ts-ignore
				//'background-color': '#eeeeee',
				//'backgroundColor': '#eeeeee',
				backgroundColor: '#ffffff',
				//'color': '#444',
				penColor: '#444',
				//'showUndoButton': false,
				//'signatureLine': false,
				onEnd: () => {
					formRef.current && formRef.current.updateValidity();
				},
			});
			signaturePadInstance.clear();
			setFirmaInstance(signaturePadInstance);
		} else {
			console.error('signatureRef is null');
		}
		window.addEventListener("resize", resizeCanvas);

		if (!isProduction() && config.debugFirma ) {
			setDomValue('input[name="nome"]', 'ciccio');
			setDomValue('input[name="cognome"]', 'pasticico');
			setDomValue('input[name="citta"]', 'Cellole');
			setDomValue('input[name="email"]', 'agx@linux.it');
			setDomValue('input[name="nascita-day"]', '03');
			setDomValue('input[name="nascita-month"]', '06');
			setDomValue('input[name="nascita-year"]', '2000');
			setDomValue('input[name="nascita"]', '2000-06-03');
			setDomChecked('input[name="sesso"]');
			setDomChecked('input[name="is_rivendita"][value="0"]', true);
			setDomChecked('input[name="privacy1"]');
			setDomChecked('input[name="privacy2"]');
			setDomChecked('input[name="privacy3"]');
			setDomChecked('input[name="privacy4"]');
			setDomChecked('input[name="privacy5"]');
			setDomChecked('input[name="privacy6"]');
			formRef.current.updateValidity();
			//const cittaEl = document.querySelector<HTMLInputElement>('input[name=citta]') as HTMLInputElement;
			//triggerChangeEvent(cittaEl);
		}

		// UNMOUNT
		return function FirmaPageDestroy() {
			console.log('--- FirmaPage Unmount ---');
			window.removeEventListener("resize", resizeCanvas);
			if (signaturePadInstance) {
				console.log('- removing signature instance');
				signaturePadInstance.off();
				setFirmaInstance(null);
			}
		}
	}, []);


	const cancellaFirma = () => {
		if (!firmaInstance) {
			console.error('no firma in cancella firma');
			return;
		}
		firmaInstance.clear();
		//setFormValid(false);
		formRef.current && formRef.current.updateValidity();
	};


	const checkCitta = (): boolean => {
		const cittaEl: HTMLInputElement = document.querySelector('input[name=citta]') as HTMLInputElement;
		if (!cittaEl) return false;
		const citta = cittaEl.value.toLowerCase();

		// Find matched items
		let v: boolean = false;
		for (let i = 0; i < pedroniState.elencoCitta.length; i++) {
			const title = pedroniState.elencoCitta[i].title;
			if (title.toLowerCase() === citta) {
				v = true;
				break;
			}
		}

		if (v) {
			cittaEl.setCustomValidity('');
			cittaEl.checkValidity();
		} else {
			cittaEl.setCustomValidity('Scegli una città dall\'elenco');
		}
		getInstance().input.validate(cittaEl);
		formRef.current && formRef.current.updateValidity();
		//setForceUpdate();
		return v;
	};



	const checkEmail = (): void => {
		const emailEl: HTMLInputElement = document.querySelector('input[name=email]') as HTMLInputElement;
		if (!emailEl) return;
		const email = emailEl.value;
		if (validateEmail(email)) {
			emailEl.setCustomValidity('');
			emailEl.checkValidity();
		} else {
			emailEl.setCustomValidity('Email non valida');
		}

		getInstance().input.validate(emailEl);

		//const containerEl: HTMLInputElement = document.querySelector('.email-container') as HTMLInputElement;
		//getInstance().input.validate(containerEl);

		formRef.current && formRef.current.updateValidity();
		//setForceUpdate();
	};


	const resizeCanvas = () => {
		const ratio = Math.max(window.devicePixelRatio || 1, 1);
		const canvas = signatureRef.current;
		if (!canvas) {
			console.error('canvas invalid');
			return;
		}
		canvas.width = canvas.offsetWidth * ratio;
		canvas.height = canvas.offsetHeight * ratio;
		const context = canvas.getContext("2d");
		if (context) {
			context.scale(ratio, ratio);
		} else {
			console.error('canvas context is null');
		}
	};


	const onValidityChange = (v: any, o: ValidatedForm) => {

		if (!v) {
			setFormValid(false);
			return;
		}

		/*if (!formRef.current) {
			setFormValid(false);
			return;
		}*/

		const values: any = o.getFormValues();
		const nascita = new Date(values.nascita);
		v = has18Years(nascita);
		if (!v) {
			console.warn('minorenne');
			setFormValid(false);
			return;
		}

		if (!firmaInstance) {
			//console.warn('firmaInstance', firmaInstance);
			setFormValid(false);
			return;
		}

		if (firmaInstance.isEmpty()) {
			//console.warn('firma');
			setFormValid(false);
			return;
		}

		setFormValid(true);
	};


	const doSubmit = () => {

		if (!formRef) {
			console.error('no form ref');
			return;
		}
		if (!formRef.current) {
			console.error('no form ref current');
			return;
		}
		let valid: boolean = formRef.current.getValidity();
		let dialog = getInstance().dialog;
		if (!valid) {
			console.error('doSubmit: form invalido');
			dialog.alert("Ci dispiace manca qualche dato");
			return;
		}
		if (!firmaInstance) {
			console.error('doSubmit: no state firma');
			dialog.alert("Errore nella firma");
			return;
		}

		if (firmaInstance.isEmpty()) {
			console.error('doSubmit: empty firma');
			dialog.alert("Per favore apponi la firma prima di proseguire");
			return;
		}

		const values = formRef.current.getFormValues();
		values.firma = firmaInstance.toDataURL("image/jpeg");

		aggiungiIscritto(values);

		toastSuccess('Iscrizione riuscita');

		// RESETTA
		//formRef.current.reset();
		firmaInstance.clear();

		setDomValue('input[name="nome"]', '');
		setDomValue('input[name="cognome"]', '');
		setDomValue('input[name="citta"]', '');
		setDomValue('input[name="email"]', '');
		setDomValue('input[name="nascita-day"]', '');
		setDomValue('input[name="nascita-month"]', '');
		setDomValue('input[name="nascita-year"]', '');
		setDomValue('input[name="nascita"]', '');
		setDomChecked('input[name="sesso"]', false);
		setDomChecked('input[name="is_rivendita"][value="0"]', true);
		setDomChecked('input[name="privacy1"]', false);
		setDomChecked('input[name="privacy2"]', false);
		setDomChecked('input[name="privacy3"]', false);
		setDomChecked('input[name="privacy4"]', false);
		setDomChecked('input[name="privacy5"]', false);
		setDomChecked('input[name="privacy6"]', false);


		document.querySelector('.page-current .page-content')!.scrollTo({top: 0, left: 0});
		document.querySelector<HTMLInputElement>('input[name=nome]')!.focus();

	};

	console.log('- render'); // TODO

	return (
		<Page>
			<Navbar>
				<NavLeft backLink/>
				<NavTitle>{pedroniState.eventoSelezionatoName}</NavTitle>
				<NavRight>
					<Button href="/email/"
						iconF7="email" fill
					>Sono già iscritto!</Button> &nbsp;
					<Button href="/qrcode/"
						iconF7="qrcode" fill
					>Ho il QrCode!</Button> &nbsp;

				</NavRight>
			</Navbar>

			<ValidatedForm options={{id: "firma"}} onValidityChange={onValidityChange}
				/*
				// @ts-ignore */
				ref={formRef}
			>
				<List noHairlines>
					{/* 			(ngSubmit)="doInvio()" (keydown.enter)="doKeydownEnter($event) */}

					<li className="row">

						<ListInput
							label="Nome"
							name="nome"
							type="text"
							placeholder=""
							autocapitalize="on" autocomplete="off"
							validate required minlength={2}
							className="col-50"
							wrap={false}
						>
						</ListInput>

						<ListInput
							label="Cognome"
							name="cognome"
							type="text"
							placeholder=""
							autocapitalize="on" autocomplete="off"
							validate required minlength={2}
							className="col-50"
							wrap={false}
						>
						</ListInput>
					</li>

					<li className="row">

						<ListInput
							id="citta"
							label="Vivo a"
							name="citta"
							type="text"
							placeholder=""
							autocapitalize="on" autocomplete="off"
							validate required minlength={2}
							className="col-50"
							wrap={false}
							onChange={checkCitta}
							onBlur={checkCitta}
						>
						</ListInput>

						<ListInput
							label="E-mail"
							name="email"
							type="email"
							placeholder=""
							autocapitalize="off" autocomplete="off"
							validate required
							className="col-50 email-container"
							wrap={false}
							onChange={checkEmail}
							onBlur={checkEmail}
						>
						</ListInput>

					</li>

					<li className="row">

						{/* In caso di problemi sostituire con: https://framework7.io/docs/picker.html */}
						{/*
						<ListInput
							id="nascita"
							label="Data di nascita"
							name="nascita"
							type="date"
							placeholder="Data di nascita"
							autocapitalize="off" autocomplete="off"
							className="col-50 unstyled"
							wrap={false}
							required
						>
						</ListInput>
						*/}

						<div className="col-50">
							<DataDiNascita name="nascita" title="Data di nascita" required />
						</div>

						<div className="col-50 ">
							<PrivacyChoice name="sesso" title="Sesso" label1="Maschio" value1="M" label2="Femmina"
								value2="F"/>
						</div>

					</li>

					<li className="row">
						<div className="col-50 ">
							<PrivacyChoice name="is_rivendita" title="Sei un tabaccaio?" label1="Sì" value1="1" label2="No"
							               value2="0"/>
						</div>
					</li>

					{/* P1 */}
					<ListItem
						title="Condizioni d'uso del sito del Pedroni Social Club"
						after="Visualizza condizioni" noChevron={false} link="#"
						popupOpen=".visualizza-condizioni-popup"
						className="no-border item-after-link"
					>
						<div slot="footer">
							L’iscrizione al Pedroni Social Club e il click sul tasto “accetto” valgono congiuntamente,
							agli
							effetti di legge, come firma elettronica ad approvazione delle clausole sopra indicate.
						</div>
					</ListItem>

					<li>
						<PrivacyChoice name="privacy1" title="" label2={undefined} classWrap="flex-row-vcenter-center"/>
					</li>

					{/* P2 */}
					<ListItem
						title="Approvazione ai sensi degli articoli 1341 e 1342 c.c."
						className="no-border"
					>
						<div slot="footer">
							Per perfezionare il presente accordo è altresì necessario fare click sul link ”ACCETTO”
							e ciò in segno di accettazione e di approvazione espressa, anche ai sensi degli articoli
							1341 e
							1342 cod. civ., delle disposizioni contenute nei seguenti articoli: art. 2. (variazioni
							delle
							condizioni); art. 5 (contenuti utenti: limiti e divieti)], art. 6 (controllo contenuti
							utenti),
							art. 7 (regole e limiti generali all’utilizzo del Club e del Sito), art 8 (limitazione ed
							esclusione di responsabilità), art. 9 (Limitazione delle garanzie del Proprietario), art.10
							(Recesso e risoluzione), art.13 (Cessione diritti sui contenuti trasmessi), art. 15
							(giurisdizione e foro competente).
							<br/>
							L’iscrizione al Pedroni Social Club e il click sul tasto ”ACCETTO” valgono congiuntamente,
							agli
							effetti di legge, come firma elettronica ad approvazione
							delle clausole sopra indicate
						</div>

					</ListItem>

					<li>
						<PrivacyChoice name="privacy2" title="" label2={undefined} classWrap="flex-row-vcenter-center"/>
					</li>

					{/* P3 */}
					<ListItem
						title="Informativa in materia di protezione dei dati personali"
						after="Visualizza l'informativa" noChevron={false} link="#"
						popupOpen=".visualizza-informativa-popup"
						className="no-border item-after-link"
					>
					</ListItem>
					<ListItem
						className="no-border"
					>
						<div slot="footer">
							<b>PRESTAZIONE DEL CONSENSO</b>
							<p>Presa visione dell'Informativa Privacy sopra riportata AI SENSI DEL REGOLAMENTO (UE)
								2016/679
								(“GDPR”) consapevole che il mio consenso è puramente facoltativo, oltre che revocabile
								in
								qualsiasi momento, esprimo il consenso al trattamento dei miei dati da parte di
								Manifatture
								Sigaro Toscano S.p.A e in particolare:</p>

							<p><b>ANALISI DELLE ABITUDINI E DELLE SCELTE DI CONSUMO:</b></p>
							<p>Accetto e presto il consenso al trattamento suddetto. Il Titolare procederà in questo
								caso ad
								utilizzare i dati personali raccolti per le finalità di analisi delle abitudini e delle
								scelte di consumo.</p>
							<p>Non accetto e non presto il consenso al trattamento suddetto. In questo caso NON verranno
								espletate le attività di analisi delle abitudini e delle scelte di consumo.</p>
						</div>

					</ListItem>

					<li>
						<PrivacyChoice name="privacy3" title="" classWrap="flex-row-vcenter-center"/>
					</li>


					{/* P4 */}
					<ListItem
						title="NEWSLETTER E ATTIVITA’ DI MARKETING:"
						className="no-border"
					>
						<div slot="footer">
							Accetto e presto il consenso al trattamento suddetto, Il Titolare procederà in questo caso
							ad
							utilizzare i dati personali raccolti per inviarle le NEWSLETTER e per altre attività di
							marketing.
							<p>Non accetto e non presto il consenso al trattamento suddetto. In questo caso NON sarà
								possibile inviare le NEWSLETTER e per altre attività di marketing.</p>
						</div>
					</ListItem>

					<li>
						<PrivacyChoice name="privacy4" title="" classWrap="flex-row-vcenter-center"/>
					</li>

					{/* P5 */}
					<ListItem
						title="COMUNICAZIONE A TERZI:"
						className="no-border"
					>
						<div slot="footer">
							Comunicazione di dati relativi all’analisi di abitudini e scelte di consumo:

							<p>Accetto e presto il consenso al trattamento suddetto. Il Titolare procederà in questo
								caso a
								comunicare ai soggetti terzi suindicati i dati personali raccolti per la finalità di
								analisi
								di abitudini e scelte di consumo.</p>

							<p>Non accetto e non presto il consenso al trattamento suddetto. In questo caso i dati
								relativi
								all’analisi di abitudini e scelte di consumo NON verranno comunicati.</p>
						</div>
					</ListItem>

					<li>
						<PrivacyChoice name="privacy5" title="" classWrap="flex-row-vcenter-center"/>
					</li>

					{/* P6 */}
					<ListItem
						title="COMUNICAZIONE DI DATI RELATIVI ALLA GEOLOCALIZZAZIONE:"
						className="no-border"
					>
						<div slot="footer">
							Accetto e presto il consenso al trattamento suddetto. Il Titolare procederà in questo caso a
							comunicare ai soggetti terzi suindicati i dati personali raccolti per la finalità di
							geolocalizzazione.
							<p>Non accetto e non presto il consenso al trattamento suddetto. In questo caso i dati
								relativi
								alla geolocalizzazione NON verranno comunicati.</p>
						</div>
					</ListItem>

					<li>
						<PrivacyChoice name="privacy6" title="" classWrap="flex-row-vcenter-center"/>
					</li>


					{/* FIRMA
				https://github.com/agilgur5/react-signature-canvas
				https://github.com/szimek/signature_pad
				*/}
					<ListItem
						title="Firma"
						className="no-border"
					>

					</ListItem>

					<li className="no-border">
						<div id="canvasContainer">
							<canvas id="signature-pad" ref={signatureRef}/>
						</div>
					</li>

					<ListButton color="orange" title="Cancella la firma" onClick={cancellaFirma}/>

				</List>
			</ValidatedForm>

			<Toolbar tabbar labels position="bottom"
				color={isFormValid ? 'red' : 'green'}
			>
				{/*
				<Link text="ISCRIVITI 1" iconF7="enter" disabled={!state.valid}
					onClick={doSubmit}
					color={!state.valid?'red':'green'}
				/>
				<Link text="ISCRIVITI 2" iconF7="enter" disabled={state.valid}
					onClick={doSubmit}
					color={state.valid?'red':'green'}
				/>
				*/}

				<div className="flex-row-vcenter-center">
					<Button color={isFormValid ? 'lightblue' : 'red'} disabled={!isFormValid} type="submit"
						iconF7="enter_round" raised fill className="display-flex wauto"
						onClick={doSubmit}
					><span
						className="flex-grow">ISCRIVITI</span></Button>
				</div>

			</Toolbar>

			<VisualizzaCondizioniPopup/>
			<VisualizzaInformativaPopup/>
		</Page>
	);

};
