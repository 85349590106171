// @flow

export function triggerEvent(el:HTMLElement, stEventName:string) {
	const event = document.createEvent('Event');
	event.initEvent(stEventName, true, true);
	el.dispatchEvent(event);
}


export function triggerChangeEvent(el:HTMLInputElement) {
	const event = document.createEvent("HTMLEvents");
	event.initEvent("change", false, true);
	el.dispatchEvent(event);
}


export function setDomValue(selector:string, value:string) {
	const el = document.querySelector<HTMLInputElement>(selector);
	if (!el) {
		console.error('setDomValue failed for',selector);
		return false;
	}
	el.value = value;
	return el;
}


export function setDomChecked(selector:string, value:boolean=true) {
	const el = document.querySelector<HTMLInputElement>(selector);
	if (!el) {
		console.error('setDomChecked failed for',selector);
		return false;
	}
	el.checked = value;
	return el;
}

export const delay = (ms:number) => new Promise(resolve => setTimeout(resolve, ms));
