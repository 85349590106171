// @flow strict

import React, {useEffect} from 'react';
import {BlockTitle, Icon, Link, List, ListItem, Navbar, NavTitle, NavTitleLarge, Page, Toolbar} from 'framework7-react';

import {build} from '../metadata.json';
import {useStore} from 'laco-react';
import {
	ServiceWorkerAddToHome,
	ServiceWorkerCheckUpdate,
	ServiceWorkerIsAppStandalone,
	ServiceWorkerStore
} from '../laco/service-worker';
import {
	inviaDati,
	IPedroniStore,
	PedroniStore,
	PedroniStoreLoadAllFromDevice,
	PedroniStoreLoadAllFromInternet,
	PedroniStoreSelezionaEventoById
} from '../laco/pedroni';
import {getInstance, getRouter, toastInfo} from '../laco/f7agx';
import {setAuth} from '../laco/auth';
import './HomePage.scss';
import {config} from '../app/config';
import {logError} from "../laco/general";


export const HomePage = () => {

	console.log('--- HomePage render() ---');

	/*
	const testUpdate = () => {
		console.log('testUpdate');
		let c: boolean = window.confirm("Confermi l'aggiornamento?");
		if (c) {
			ServiceWorkerSkipWaiting();
		}
	};
*/

	const swState = useStore(ServiceWorkerStore);

	const pedroniState: IPedroniStore = useStore(PedroniStore);


	const doSelezionaEvento = (res: React.ChangeEvent<HTMLSelectElement>) => {
		console.log('- doSelezionaEvento()');
		let id: number = parseInt(res.target.value);
		PedroniStoreSelezionaEventoById(id);
	};


	const doUpdateApp = async (ev: React.ChangeEvent<HTMLElement>) => {
		ev.preventDefault();

		let avail: boolean = await ServiceWorkerCheckUpdate();
		if (!avail) {
			toastInfo('Nessun aggiornamento disponibile', 2000);
		}
	};


	const doLogout = (ev: React.ChangeEvent<HTMLElement>) => {
		ev.preventDefault();
		setAuth(-1);
		getRouter().navigate('/login/');
	};

	useEffect(() => {
		console.log('=== HomePage UseEffect === ');
		//ServiceWorkerSetOnUpdateAvailable(testUpdate);
		PedroniStoreLoadAllFromDevice().catch(logError);
	}, []);


	const disallowFirma: boolean = pedroniState.eventoSelezionatoId === null || pedroniState.numeroEventiInElenco === 0 || pedroniState.numeroCittaInElenco === 0;


	//const debug = (s)=>{console.log(s)};

	return (
		<Page>

			<Navbar>
				<NavTitle>{config.BRAND} iHostess - v:{build}</NavTitle>
			</Navbar>

			<BlockTitle className="block-title-evento">Evento Attuale: <span>{pedroniState.eventoSelezionatoName}</span></BlockTitle>

			<List>

				<ListItem title="Registrazione con firma" link="/firma/" disabled={disallowFirma}
				>
					<Icon slot="media" f7="edit"/>
				</ListItem>

				<ListItem title="Ricerca socio per email" link="/email/"
					disabled={pedroniState.eventoSelezionatoId === null || pedroniState.numeroUtentiInElenco === 0}
				>
					<Icon slot="media" f7="search"/>
				</ListItem>

				<ListItem title="Scansione QR-code tessera" link="/qrcode/"
					disabled={pedroniState.eventoSelezionatoId === null || pedroniState.numeroUtentiInElenco === 0}
				>
					<Icon slot="media" f7="qrcode"/>
				</ListItem>
			</List>

			<BlockTitle>Gestione Evento</BlockTitle>

			<List>{/* mediaList */}
				<ListItem title="Seleziona un evento" link="#" disabled={pedroniState.numeroEventiInElenco <= 0}
					smartSelect smartSelectParams={{
					openIn: 'popup', searchbar: true, searchbarPlaceholder: 'Cerca per nome',
					virtualList: true, closeOnSelect: true
				}}
					className="hide-item-after"
				>
					<Icon slot="media" f7="gear"/>

					<select name="event"
						onChange={doSelezionaEvento}
						value={pedroniState.eventoSelezionatoId || ''}
					>
						<option value="">Nessuno</option>
						{pedroniState.elencoEventi.map(evento =>
							(
								<option key={evento.id} value={evento.id}>{evento.title}</option>
							)
						)}
					</select>
				</ListItem>

				{/* usa text con mediaList o footer */}
				<ListItem title="Aggiorna dati" link="#" onClick={PedroniStoreLoadAllFromInternet}
					after={`Eventi: ${pedroniState.numeroEventiInElenco} - Città: ${pedroniState.numeroCittaInElenco} - Utenti: ${pedroniState.numeroUtentiInElenco}`}
				>
					<Icon slot="media" f7="refresh"/>
				</ListItem>

				<ListItem title="Elenco iscritti da inviare" link="/coda/"
					disabled={pedroniState.numeroIscrittiInCoda <= 0 && pedroniState.numeroPartecipantiInCoda <= 0}
					after={`Iscritti: ${pedroniState.numeroIscrittiInCoda} - Partecipanti: ${pedroniState.numeroPartecipantiInCoda}`}
				>
					<Icon slot="media" f7="favorites"/>
				</ListItem>

				<ListItem title="Invia iscrizioni" onClick={inviaDati}
					disabled={pedroniState.numeroIscrittiInCoda <= 0 && pedroniState.numeroPartecipantiInCoda <= 0}
				>
					<Icon slot="media" f7="rocket"/>
				</ListItem>

			</List>

			<BlockTitle>Gestione App</BlockTitle>

			<List>
				{!ServiceWorkerIsAppStandalone() ?
					<ListItem title="Installa su questo device" disabled={!swState.beforeinstallprompt}
						onClick={ServiceWorkerAddToHome}
					>
						<Icon slot="media" f7="cloud_download"/>
					</ListItem>
					:
					<ListItem title="App installata correttamente" disabled={true}>
						<Icon slot="media" f7="cloud_download"/>
					</ListItem>
				}

				<ListItem title="Controlla aggiornamenti" link="#"
					disabled={!swState.ready}
					onClick={doUpdateApp}>
					<Icon slot="media" f7="refresh_round"/>
				</ListItem>

				<ListItem title="Logout" onClick={doLogout}>
					<Icon slot="media" f7="exit"/>
				</ListItem>

			</List>

			<Toolbar tabbar labels position="bottom">
				<Link text="Firma" iconF7="edit"
					className={disallowFirma === null ? 'disabled' : ''}
					onClick={() => {
						getRouter().navigate('/firma/')
					}}
				/>
				<Link text="Email" iconF7="search"
					className={pedroniState.eventoSelezionatoId === null || pedroniState.numeroUtentiInElenco === 0 ? 'disabled' : ''}
					href="/email/"
				/>
				<Link text="QrCode" iconF7="qrcode"
					className={pedroniState.eventoSelezionatoId === null || pedroniState.numeroUtentiInElenco === 0 ? 'disabled' : ''}
					href="/qrcode/"
				/>
			</Toolbar>

		</Page>
	);

};
