// @flow

import {checkAuth, checkFirmaAuth} from '../laco/auth';
import {HomePage} from '../pages/HomePage';
import {NotFoundPage} from '../pages/NotFoundPage';
import {LoginPage} from '../pages/LoginPage';
import {QrCodePage} from '../pages/QrCodePage';
import {FirmaPage} from '../pages/FirmaPage';
import {CercaPage} from '../pages/CercaPage';
import {Router} from "framework7/modules/router/router";
import {CodaPage} from "../pages/CodaPage";


export const routes: Array<Router.RouteParameters> = [
	{
		path: '/',
		component: HomePage as Router.Component,
		beforeEnter: checkAuth,
	},
	{
		path: '/login/',
		component: LoginPage as Router.Component,
	},
	{
		path: '/email/',
		component: CercaPage as Router.Component,
		beforeEnter: checkFirmaAuth,
	},
	{
		path: '/qrcode/',
		component: QrCodePage as Router.Component,
		beforeEnter: checkFirmaAuth,
	},
	{
		path: '/firma/',
		component: FirmaPage as Router.Component,
		beforeEnter: checkFirmaAuth,
		/*async(routeTo, routeFrom, resolve, reject) {
			// dynamic import component; returns promise
			const reactComponent = () => import('../pages/FirmaPage');
			// resolve promise
			reactComponent().then((rc) => {
				// resolve with component
				resolve({ component: rc.default })
			});
		}*/
	},
	{
		path: '/coda/',
		component: CodaPage as Router.Component,
		beforeEnter: checkFirmaAuth,
	},
	{
		path: '(.*)',
		component: NotFoundPage as Router.Component,
	},
];
