// @flow strict

import React from 'react';
import {Block, Link, Navbar, NavRight, Page, Popup} from 'framework7-react';

//import './FirmaPage.scss';


export default () => {

	//console.log('- VisualizzaCondizioniPopup render()');

	return (
		<Popup className="visualizza-condizioni-popup" tabletFullscreen={true} backdrop={true} swipeToClose={true} closeByBackdropClick={true}>
			<Page>
				<Navbar title="Condizioni Generali d'Utilizzo">
					<NavRight>
						<Link popupClose>Chiudi</Link>
					</NavRight>
				</Navbar>
				<Block>
					<p>Le presenti condizioni generali di utilizzo del sito
						“<strong>pedronisocialclub.it</strong>” <strong>(di seguito il “Sito”</strong>) e del Pedroni
						Social Club (di seguito il “<strong>Club</strong>”) stabiliscono i diritti e doveri degli
						utenti. Il Sito ed il Club sono di proprietà di Manifatture Sigaro Toscano S.p.A.
						(“<strong>Proprietario</strong>” o “<strong>MST</strong>”), ed attualmente il Sito ed il Club
						sono gestiti da LGS S.r.l. (“<strong>Gestore</strong>”).</p>

					<p>&nbsp;</p>

					<p>L’accesso al Sito, l'accesso all'area web del Club, il loro utilizzo e la fruizione dei
						servizi offerti dal Sito, e dal Club sono pertanto, regolati e soggetti alle condizioni di
						seguito riportate (“<strong>Condizioni</strong>”).</p>

					<p>Ogni utente che accede ed utilizza il Sito (per alcuni servizi), il Club deve iscriversi
						eseguendo correttamente le procedure di registrazione, e deve accettare le presenti
						Condizioni, impegnandosi a rispettarle. Qualora l'utente non accetti tutte le Condizioni di
						utilizzo, è pregato di abbandonare immediatamente il Sito ed il Club.</p>

					<p>L'utilizzo del Sito e del Club da parte dell'utente presuppone l'accettazione delle
						Condizioni e dell’informativa privacy.</p>

					<p>Per quanto riguarda i profili di privacy connessi al presente Sito ed al Club, si prega di
						prendere visione della sezione relativa <u><strong>all’Informativa
							sulla Privacy</strong></u>.</p>

					<p>&nbsp;</p>

					<p>ATTENZIONE: LE PRESENTI CONDIZIONI REGOLANO L’UTILIZZO DEL SITO E DEL CLUB. SIETE PREGATI DI
						LEGGERE ATTENTAMENTE I SEGUENTI TERMINI E CONDIZIONI PRIMA DI NAVIGARE SUL SITO E DI
						ISCRIVERSI AL CLUB. IL PROPRIETARIO SI RISERVA LA FACOLTÀ, A PROPRIA ESCLUSIVA DISCREZIONE,
						DI MODIFICARE DI VOLTA IN VOLTA LE PRESENTI CONDIZIONI. L'UTILIZZO DEL SITO E DEL CLUB
						SUCCESSIVO A TALI MODIFICHE COMPORTA L'ACCETTAZIONE DELLE CONDIZIONI DI UTILIZZO NELLA LORO
						VERSIONE MODIFICATA.</p>

					<p><strong>L’UTILIZZO </strong><strong>DEL PRESENTE SITO E DEL CLUB COSTITUISCE ACCETTAZIONE
						DELLE PRESENTI CONDIZIONI. SE NON ACCETTATE LE CONDIZIONI SIETE PREGATI DI NON USARE IL SITO
						ED IL CLUB.</strong></p>

					<p>VI PREGHIAMO ALTRESÌ DI PRENDERE VISIONE DELLA SEZIONE RELATIVA ALL’INFORMATIVA SULLA
						PRIVACY, ACCESSIBILE TRAMITE IL SEGUENTE LINK: <u>[Informativa
							Privacy]</u></p>

					<p>PER QUALSIASI INFORMAZIONE POTRETE CONTATTARE MST ALL’INDIRIZZO <u>info@pedronisocialclub.it</u></p>

					<p>Il Club è messo a disposizione esclusivamente di utenti maggiorenni e gratuitamente, senza
						ulteriori garanzie oltre a quelle espressamente previste. Le informazioni contenute al loro
						interno si intendono esclusivamente a scopo divulgativo.</p>

					<p>&nbsp;</p>

					<p><strong>1. scopo del sito e del club</strong></p>

					<p>Salvo ove diversamente previsto nel Sito e/o nel Club, il Proprietario e il Gestore non sono
						responsabili per i contenuti presenti nel Sito e nel Club relativi a terzi, ovvero
						dell’utilizzo delle pagine web da parte degli utenti. MST ha adottato, per quanto di propria
						competenza, tutte le misure possibili per assicurare che le informazioni contenute nel Sito
						e nel Club siano corrette, tuttavia non garantisce la correttezza, accuratezza e completezza
						delle medesime e dei materiali presenti negli stessi. Il materiale presente sul Sito e sul
						Club potrebbe contenere imprecisioni o refusi. Il Proprietario non potrà essere ritenuto
						responsabile di eventuali imprecisioni ed errori né di perdite o danni causati o derivanti
						dall'affidamento fatto dagli utenti sulle informazioni ricavate dal Sito o dal Club o
						tramite essi. È responsabilità dell'utente valutare le informazioni ed il contenuto
						ottenibili mediante il Sito ed il Club. Il presente Sito ed il Club e tutte le informazioni
						ed il materiale in essi contenuti potranno essere soggetti a modifica in qualsiasi momento e
						di volta in volta e senza alcun obbligo di comunicazione preventiva o successiva.</p>

					<p>&nbsp;</p>

					<p>Il Proprietario invita gli utenti a non inserire e pubblicare sul Sito e sul Club dati
						personali particolari (sono dati particolari, ai sensi dell’art. 9 del Regolamento (UE) n.
						679/2016 relativo alla protezione delle persone fisiche con riguardo al trattamento dei dati
						personali nonché alla libera circolazione di tali dati (“GDPR”), i dati personali che “<em>rivelino
							l'origine razziale o etnica, le opinioni politiche, le convinzioni religiose o
							filosofiche, o l'appartenenza sindacale, nonché trattare dati genetici, dati biometrici
							intesi a identificare in modo univoco una persona fisica, dati relativi alla salute o
							alla vita sessuale o all'orientamento sessuale della persona”)</em>né eventuali
						immagini, ritratti o fotografie di soggetti terzi ed informa in ogni caso che, in caso di
						mancata osservanza, MST potrà cancellare/non pubblicare i medesimi.</p>

					<p>&nbsp;</p>

					<p>Tutti i copyright, i marchi registrati e gli altri diritti di proprietà industriale ed
						intellettuale utilizzati all’interno del Sito e del Club sono del Proprietario e/o dei
						rispettivi licenzianti. Con l’utilizzazione del Sito e del Club gli utenti non acquisiscono
						alcun diritto sugli stessi, se non quello temporaneo di utilizzazione, secondo le presenti
						Condizioni. È possibile stampare o scaricare informazioni e contenuti da questo Sito e dal
						Club solo ed esclusivamente per uso personale e non commerciale. Non è consentito vendere o
						distribuire, con qualsiasi mezzo, parti del Sito o del Club o i contenuti dei medesimi. Non
						è consentito, altresì, pubblicare parti del Sito o del Club in altri siti, con qualsiasi
						mezzo, se non previa autorizzazione scritta del Proprietario.</p>

					<p>È vietata l’utilizzazione del Sito e del Club per scopi non conformi alla legge. In
						particolare, è vietato inviare, utilizzare, copiare, inserire o autorizzare l’inserimento di
						messaggi che contengano minacce e/o che possano avere un contenuto diffamatorio, osceno,
						offensivo, pornografico, abusivo, che possa indurre all’odio razziale, a carattere
						discriminatorio, minaccioso, scandaloso, provocatorio, blasfemo, che violi la riservatezza,
						la privacy o che possa recare fastidio, molestie o disturbi. Nessuno potrà inviare materiale
						promozionale o pubblicitario non richiesto, spamming o simili, né messaggi voluminosi che
						possano interferire con il funzionamento del Sito e del Club o con la fruizione del medesimo
						da parte di altri utenti. Resta inteso che, il Proprietario si riserva il diritto in
						qualsiasi momento e senza preavviso di potenziare, modificare, alterare, sospendere o
						disattivare permanentemente in tutto o in parte il Sito ed il Club e/o di limitare o
						proibire l’accesso anche a determinati utenti.</p>

					<p>Resta inteso che, gli utenti del Sito e del Club si obbligano a manlevare e tenere indenne il
						Proprietario e/o il Gestore da qualsiasi responsabilità, pretesa risarcitoria di terzi,
						perdita, danno, costo o spesa (ivi incluse le spese legali), avanzati nei confronti del
						Proprietario e/o del Gestore in conseguenza, occasione o connessione all’uso improprio del
						Sito e del Club o della violazione da parte degli utenti delle presenti Condizioni.</p>

					<p>&nbsp;</p>

					<p>Il Sito ed il Club possono contenere link ad altri siti sui quali il Proprietario e/o il
						Gestore non hanno alcun controllo. L’uso di tali altri siti, pertanto, è ad esclusivo
						rischio dell’utente.</p>

					<p>In caso di violazione delle presenti Condizioni, il Proprietario si riserva il diritto di
						sospendere o bloccare l’accesso a questo Sito ed al Club.</p>

					<p>&nbsp;</p>

					<p><strong>2.</strong> <strong>variazioni delle condizioni</strong></p>

					<p>Il Proprietario si riserva il diritto di variare o modificare in qualsiasi momento le
						presenti Condizioni, a sua sola ed esclusiva discrezione, senza preavviso. Le modifiche alle
						Condizioni sono messe a conoscenza degli utenti tramite pubblicazione sul Sito e sul Club.
						Gli utenti che accedono al Club dopo che le modifiche sono state pubblicate sul Sito e sul
						Club accettano dette modifiche. Si consiglia, perciò, di visionare e controllare
						periodicamente sul Sito e sul Club le Condizioni in vigore.</p>

					<p>I servizi offerti agli utenti sul Sito e sul Club, tra i quali la registrazione al Club
						possono essere modificati e/o interrotti in qualsiasi momento dal Proprietario per qualsiasi
						motivo e discrezionalmente, senza preavviso e senza alcuna responsabilità nei confronti
						dell’utente.</p>

					<p>&nbsp;</p>

					<p><strong>3. iscrizione ed accesso al club</strong></p>

					<p>L'accesso al Club presente sul Sito e la fruizione dei servizi del Club sono consentiti solo
						ad utenti registrati. L’iscrizione al Club, l’accesso e l’utilizzo delle utilities del Club
						e fruizione dei relativi servizi sono gratuiti e riservati alle sole persone maggiorenni e
						residenti in Italia, previa registrazione secondo l’apposita procedura, che richiede
						l’accettazione delle presenti Condizioni, la presa visione dell’informativa privacy ed il
						rilascio ove necessario dei consensi al trattamento dei dati personali.</p>

					<p>La registrazione al Club è personale, è relativa esclusivamente all'utente che inserisce i
						dati durante la procedura di registrazione e non è cedibile a terzi. La compilazione e
						l’invio del modulo di registrazione da parte dell'utente comporta la conoscenza e
						l'accettazione delle presenti Condizioni. Solo chi rispetta le Condizioni ed in particolare
						le procedure di registrazione possiede i requisiti per essere denominato utente
						registrato.</p>

					<p>Al fine di utilizzare i servizi erogati nel Club, l'utente si impegna a:</p>

					<p>• fornire le informazioni e i dati personali richiesti durante la procedura di registrazione,
						che dovranno essere aggiornati, completi e veritieri;</p>

					<p>• aggiornare tempestivamente le informazioni e i dati personali registrati, affinché questi
						siano costantemente aggiornati completi e veritieri.</p>

					<p>In sede di registrazione, verrà richiesto all'utente di fornire un'autocertificazione circa
						l'avvenuto superamento della maggiore età. Nel caso in cui fosse accertato o anche presunto
						che l'utente non abbia raggiunto la maggiore età, il Proprietario e/o il Gestore potranno
						procedere all'immediata rimozione dell'account ed il Proprietario si riserva di
						intraprendere ogni necessaria ed opportuna iniziativa legale nel caso in cui tale falsa
						dichiarazione abbia leso in qualsivoglia modo il Club.</p>

					<p>Il Proprietario ed il Gestore assicurano la sicurezza e la privacy dei propri utenti secondo
						quanto previsto dalla normativa vigente.</p>

					<p>L’utente riconosce, è consapevole ed accetta che l’utilizzo di alcuni servizi forniti
						all’interno del Club e sul Sito - quali compilazione e condivisione della pagina personale,
						a sondaggi e simili, pubblicazione di immagini, ritratti o foto, informazioni, dati
						personali o altro - comporta la diffusione, cioè la divulgazione al pubblico o ad un numero
						indeterminato di soggetti, delle informazioni e dati personali forniti dall’utente, anche
						nel rispetto, ove applicabile, di quanto stabilito all’art. 85 GDPR .</p>

					<p>L’utente riconosce ed accetta di essere l'unico responsabile per i suoi contributi personali
						e per le conseguenze della loro pubblicazione. Il Proprietario non avalla e non è in alcun
						modo responsabile per i contributi, opinioni, raccomandazioni e/o avvisi espressi dagli
						utenti sul Sito e sul Club.</p>

					<p>L’utente, altresì, si rende responsabile dei contenuti elaborati e pubblicati, ovvero della
						loro provenienza lecita e dell’autenticità dell’origine e della loro riconducibilità
						all’utente stesso, escludendo ogni forma di violazione del copyright e del diritto d’autore
						e di proprietà industriale di terzi non consenzienti. Altrettanto vale per le immagini
						caricate a corredo dei contenuti elaborati e pubblicati. In ogni caso l’utente manleva il
						Proprietario e il Gestore e li tiene indenni da ogni e qualsiasi richiesta, anche di
						risarcimento danni, proposta e/o derivante, direttamente ovvero indirettamente, dalle
						conseguenze dell’eventuale illecito perpetrato a danno di terzi per le violazioni sopra
						evidenziate. Il Proprietario e il Gestore potranno procedere all'immediata rimozione
						dell'account e dei contenuti e nel caso riscontrare agli stessi effetti l’eventuale
						richiesta o provvedimento in tal senso proveniente o adottato dalle Autorità competenti, ed
						il Proprietario si riserva fin d’ora di intraprendere ogni necessaria ed opportuna
						iniziativa legale. Anche per essi vale quanto già riportato in ordine alla divulgazione e
						alla diffusione di informazioni e contributi da parte dell’utente e/o da questi condivisi.
						Ulteriori chiarimenti al riguardo sono previsti nel successivo punto 6).</p>

					<p>L'utente è responsabile della segretezza della sua password, dell’ID e di altri eventuali
						dati nonché per tutte le attività che hanno luogo nell’account dell'utente. La registrazione
						è legata solo alla persona dell'utente. L'utente non è perciò autorizzato a comunicare a
						terzi o a mettere a loro disposizione la denominazione del suo account, il suo ID o la sua
						password.</p>

					<p>L’utente è, pertanto, il solo ed unico responsabile della custodia e dell'uso appropriato
						della propria password e del proprio account e, conseguentemente, rimane il solo ed unico
						responsabile per tutti gli usi ad essi relativi, connessi e correlati, tanto autorizzati dal
						medesimo utente, quanto non autorizzati.</p>

					<p>In relazione a quanto sopra, l'utente si impegna a comunicare immediatamente al Proprietario
						e/o al Gestore qualsiasi eventuale furto e/o smarrimento e/o uso non autorizzato da parte di
						terzi della propria password non appena venutone a conoscenza, impegnandosi comunque sin
						d'ora a manlevare e tenere indenne il Proprietario e/o il Gestore da ogni e qualsiasi
						richiesta, anche di risarcimento danni, proposta e/o derivante, direttamente ovvero
						indirettamente, da qualsiasi eventuale furto e/o smarrimento e/o uso non autorizzato da
						parte di terzi della propria password e del proprio account.</p>

					<p>&nbsp;</p>

					<p><strong>4. servizi offerti dal club</strong></p>

					<p><strong>attività on line</strong></p>

					<p>- Login e password personali per accedere al Sito ed all’App;</p>

					<p>- Pagina personale con: nome e cognome, altre informazioni dell’utente, spazio per la
						pubblicazione di foto, possibilità di personalizzare la newsletter periodica e iscriversi e
						disiscriversi dalla stessa newsletter, di visualizzare il livello raggiunto come utente, la
						possibilità di visualizzare gli eventi a cui si è interessati, di visualizzare i prodotti
						provati, gli abbinamenti preferiti, gli articoli salvati, i sondaggi a cui si partecipa, di
						visualizzare l’attività Instagram e Twitter collegata all’hashtag #pedronisocialclub e di
						lasciare recensioni sui prodotti;</p>

					<p>- Consultare e partecipare a tutte le attività presenti sul Sito e sul Club messe a
						disposizione degli iscritti, tra cui la consultazione delle schede prodotti, degli
						abbinamenti e visualizzare le tabaccherie in Italia;</p>

					<p>- Prenotazione prodotti;</p>

					<p>- Ricevere newsletter periodiche su materie di interesse;</p>

					<p>- Compilare questionari di gradimento.</p>

					<p>&nbsp;</p>

					<p><strong>attività off line</strong></p>

					<p>- Partecipare alle serate degustative (compatibilmente con la disponibilità);</p>

					<p>- Usufruire delle eventuali le convenzioni che potranno essere offerte dalle “aziende amiche
						del Club”;</p>

					<p>- Partecipare alle Visite guidate alla Manifattura di Cava de’ Tirreni;</p>

					<p>- Chiedere informazioni e consigli al Club chiamando (al n° xxxxx) o scrivendo (<u>info@pedronisocialclub.it</u>) alla Segreteria
						del Club.</p>

					<p>&nbsp;</p>

					<p><strong>5. contenuti utenti: limiti e divieti</strong></p>

					<p>L’utente conviene e concorda che tutte le informazioni, i contenuti e i materiali da lui
						trasmessi, inviati o diffusi per tramite del Sito e del Club rientrano nella propria sola ed
						esclusiva responsabilità e non costituiscono materiale riservato. Ciò significa che l’utente
						è il solo ed esclusivo responsabile per qualsivoglia contenuto caricato, pubblicato, inviato
						via e-mail, o in altro modo trasmesso o diffuso per il tramite dei servizi del Sito e del
						Club. Il Proprietario ed il Gestore non garantiscono la veridicità, la correttezza e la
						qualità di tali contenuti. Il Proprietario non è tenuto a: (a) mantenere riservati i
						contenuti, materiali ed informazioni inviati dall’utente; (b) corrispondere alcun compenso
						per l'uso dei contenuti, materiali e informazioni inviati dall’utente; (c) rispondere agli
						invii dei contenuti, materiali e informazioni inviati dall’utente o a confermarne il
						ricevimento. L'utente dichiara e garantisce che i contenuti e materiali inviati non violano
						in alcun modo eventuali diritti di terzi, ivi inclusi, a titolo meramente esemplificativo e
						non esaustivo, i diritti relativi a copyright, marchi commerciali, brevetti, segreti
						industriali, riservatezza e altri diritti di proprietà o di natura personale.</p>

					<p>L’utente si impegna a non utilizzare il Sito ed il Club ed i relativi servizi per:</p>

					<p>• caricare, pubblicare, inviare per posta elettronica o in altro modo trasmettere o
						diffondere contenuti che siano illeciti, dannosi, minatori, abusivi, molesti, diffamatori
						e/o calunniosi, volgari, osceni, lesivi della privacy altrui, razzisti, classisti o comunque
						reprensibili;</p>

					<p>• falsificare la propria identità, fermo restando che l’uso di pseudonimi può essere
						accettato (si rileva che in tale caso è comunque possibile risalire al nome dell’utente
						registrato);</p>

					<p>• inviare via e-mail o in altro modo trasmettere o diffondere un contenuto che non abbia il
						diritto di trasmettere o diffondere in forza di una previsione di legge, di contratto,
						ovvero a causa di un rapporto fiduciario (per esempio informazioni riservate, informazioni
						confidenziali apprese in forza di un rapporto di lavoro o protette da un patto di
						riservatezza);</p>

					<p>• caricare, pubblicare, inviare via e-mail o in altro modo trasmettere o diffondere un
						contenuto che comporti la violazione di brevetti, marchi, segreti, diritti di autore o altri
						diritti di proprietà industriale e/o intellettuale di terzi soggetti;</p>

					<p>• caricare, pubblicare, inviare via e-mail o in altro modo trasmettere o diffondere
						pubblicità, materiale promozionale o qualsiasi altra forma di sollecitazione non autorizzate
						o non richieste, con eccezione per quelle aree che sono specificamente dedicate a queste
						finalità, se esistenti;</p>

					<p>• caricare, affiggere, inviare via e-mail o in altro modo trasmettere o diffondere
						qualsivoglia materiale che contenga virus o altri codici, file o programmi creati per
						interrompere, distruggere o limitare il funzionamento dei software, degli hardware o degli
						impianti di telecomunicazioni di terzi;</p>

					<p>• violare, intenzionalmente o meno, qualsivoglia legge o regolamento applicabile;</p>

					<p>• perseguitare o in altro modo molestare terzi soggetti;</p>

					<p>• raccogliere o immagazzinare dati personali degli altri utenti.</p>

					<p>L’utente si impegna a non riprodurre, duplicare, copiare, vendere, rivendere e comunque a non
						sfruttare a fini commerciali i servizi offerti tramite il Sito ed il Club o una qualunque
						sua parte, nonché l’utilizzo o l’accesso ai servizi.</p>

					<p>L’utente si impegna, inoltre, a non modificare né ad usare in qualsiasi modo (sia a titolo
						gratuito sia a titolo oneroso) e a non distribuire, diffondere o creare lavori basati,
						integralmente o parzialmente, sui servizi offerti.</p>

					<p>&nbsp;</p>

					<p><strong>6.</strong> <strong>controllo contenuti utenti</strong></p>

					<p>Una volta iscritti al Club è possibile accedere a determinati e specifici servizi, utilities
						e/o informazioni. Il Proprietario ed il Gestore non assicurano alcun controllo, né
						intervento o filtraggio in altra forma dei contenuti inseriti dagli utenti. Essi non sono
						assoggettati ad un obbligo generale di sorveglianza sulle informazioni che trasmette o
						memorizza, né ad un obbligo generale di ricercare attivamente fatti o circostanze che
						indichino la presenza di attività illecite. Pertanto, l'utente si impegna a valutare, sotto
						la propria esclusiva responsabilità, compiutamente e indipendentemente ed a sopportare tutti
						i rischi associati con l'utilizzo di un contenuto. Resta salva, comunque, la facoltà del
						Gestore e/o del Proprietario di sottoporre a controllo manuale o automatico ogni tipo di
						contenuto degli utenti caricato sul Sito e sul Club, ivi compresi i settori del Sito e del
						Club nel quale gli utenti trasmettono o posizionano le loro comunicazioni. Il Proprietario
						e/o il Gestore può respingere, rifiutare di inserire sul Sito e sul Club, non utilizzare,
						rimuovere, modificare, bloccare con un divieto all'accesso e/o cancellare tutti quei
						contenuti ritenuti inadeguati o sgradevoli, o che in qualche modo contravvengano alle
						presenti Condizioni e alla normativa vigente. L'utente riconosce che il Gestore ha il
						diritto di memorizzare i contenuti nonché rivelarli a terzi, ivi incluso il Proprietario,
						ove ciò sia richiesto dalla legge o perché ciò sia necessario per:</p>

					<p>- adempiere a procedure legali e far valere o difendere un diritto in sede giudiziaria;</p>

					<p>- replicare alle contestazioni secondo cui i contenuti violano diritti di terzi;</p>

					<p>- proteggere i diritti, le proprietà o i requisiti di sicurezza del Club e del Sito e dei
						suoi utenti;</p>

					<p>- riscontrare, nei limiti e nel rispetto del D.lgs. n. 70/2003, le richieste provenienti
						dalle Autorità competenti. Ovvero il Proprietario, anche per il tramite del Gestore, è
						tenuto:</p>

					<p>a) ad informare senza indugio l'autorità giudiziaria o quella amministrativa avente funzioni
						di vigilanza, qualora sia a conoscenza di presunte attività o informazioni illecite
						riguardanti un suo destinatario del servizio della società dell'informazione;</p>

					<p>b) a fornire senza indugio, a richiesta delle autorità competenti, le informazioni in suo
						possesso che consentano l'identificazione del destinatario dei suoi servizi con cui ha
						accordi di memorizzazione dei dati, al fine di individuare e prevenire attività
						illecite.</p>

					<p>&nbsp;</p>

					<p>&nbsp;</p>

					<p><strong>7. regole e limiti generali all’utilizzo del club, del Sito e dell’App </strong></p>

					<p>L’utente riconosce ed accetta che nel Club e nel Sito possono essere stabilite regole e
						limiti generali riguardanti l’utilizzo dei servizi offerti nel Club stesso e del Sito.
						L’utente riconosce ed accetta che il Gestore ed il Proprietario possono, a propria esclusiva
						discrezione, disattivare la password e l’account dell’utente o interromperne l’utilizzo dei
						servizi, nonché rimuovere o rifiutare la diffusione di un contenuto sul Club e sul Sito.</p>

					<p>In ogni caso, qualora l’utente non effettui alcun accesso al Club per un periodo di sei (6)
						mesi, il Proprietario si riserva la facoltà di disattivare e cancellare l’utenza e tutti i
						dati e le informazioni contenuti nell’account dell’utente, ed in tal caso nessun
						risarcimento o indennità saranno dovuti all’utente.</p>

					<p>&nbsp;</p>

					<p><strong>8. limitazione ed esclusione di responsabilità</strong></p>

					<p>Ad eccezione delle informazioni personali trasmesse dall’utente a scopo di registrazione o
						aggiornamento del proprio account, il Proprietario ed il Gestore non sono responsabili delle
						informazioni, dei contenuti e materiali trasmessi dagli utenti attraverso le aree del Sito e
						del Club. L’utente riconosce e concorda che, salvo i casi di dolo o colpa grave, il
						Proprietario ed il Gestore non saranno in alcun caso responsabili nei confronti degli utenti
						per eventuali danni di qualsiasi specie e natura, anche relativi alla perdita di profitti,
						dell’avviamento commerciale, risultanti da:</p>

					<p>- un utilizzo o uno scorretto utilizzo del Sito e del Club, e dei relativi servizi;</p>

					<p>- un accesso non autorizzato o un’alterazione delle trasmissioni o dei dati dell’utente;</p>

					<p>- dichiarazioni o comportamenti di qualunque terzo soggetto.</p>

					<p>L’utente è il solo responsabile dei danni (compresi quelli che possano avere effetti sui
						contenuti del Sito e del Club) derivanti dall’utilizzo o dalla trasmissione e pubblicazione
						da parte del medesimo di contenuti, materiali ed informazioni sul Sito e sul Club e si
						obbliga a manlevare e tenere indenne MST da qualsiasi responsabilità, pretesa risarcitoria
						di terzi, perdita, danno, costo o spesa (ivi incluse le spese legali), avanzati nei
						confronti di MST in conseguenza, occasione o connessione alle suddette attività svolte
						dall’utente e comunque riferibili all’account ad esso riservato.</p>

					<p>&nbsp;</p>

					<p><strong>9. Limitazione delle garanzie del Proprietario</strong></p>

					<p>Il Proprietario non presta alcun tipo di garanzia in merito al fatto che il Sito e il Club e
						tutti i relativi servizi corrispondano ai requisiti propri e/o alle aspettative
						dell'utente.</p>

					<p>L'utente riconosce che l'accesso al Sito e al Club, la conservazione, pubblicazione e
						diffusione dei contenuti inviati è fatta a proprio rischio esclusivo.</p>

					<p>Il Proprietario, inoltre, non presta alcuna garanzia nei confronti dell'utente in merito ai
						risultati che possono essere ottenuti con l'uso del Sito e del Club, ovvero in merito alla
						veridicità e/o affidabilità di ogni informazione, dato o notizia ottenuta dall'utente per il
						tramite del Sito e del Club e dai servizi da essi offerti.</p>

					<p>&nbsp;</p>

					<p><strong>10. Recesso e risoluzione </strong></p>

					<p>Le presenti Condizioni generali di utilizzo continueranno ad essere applicate fino al recesso
						da parte dell’utente o del Proprietario o alla risoluzione secondo le modalità di seguito
						indicate.</p>

					<p>Se l’utente vuole la cancellazione del proprio profilo, potrà inviare comunicazione di
						recesso scritta, anche via e-mail, al Gestore o al Proprietario ai seguenti indirizzo <u>info@pedronisocialclub.it</u>, specificando
						i suoi login e password di accesso al Sito e al Club. In tale caso, il Gestore o il
						Proprietario provvederanno alla eliminazione del profilo dell’utente – dal Sito e dal Club e
						dai relativi database - ed alla cessazione del trattamento di tutti i dati e informazioni
						dell’utente e cancellazione dei medesimi.</p>

					<p>Il Proprietario si riserva il diritto di recedere unilateralmente ed in qualsiasi momento
						dalle presenti Condizioni, senza obbligo di alcuna motivazione e previo preavviso mediante
						messaggio di posta elettronica. Il Proprietario si riserva in ogni caso il diritto di
						recedere e/o di risolvere il rapporto instaurato con l’utente, senza preavviso e comunque a
						suo insindacabile giudizio, qualora ricorra una delle seguenti condizioni, che costituiscono
						giusta causa o giustificato motivo di recesso o di risoluzione (I) violazione da parte
						dell’utente delle Condizioni e dei termini delle presenti Condizioni (II) se l’utente causa
						malfunzionamenti e/o danni di qualunque tipo; (III) utilizzo da parte dell’utente dei
						servizi del Sito e del Club per fini illegali, per la trasmissione di virus, per la
						distribuzione non richiesta di pubblicità "spam" anche attraverso l’utilizzo di e-mail, per
						la trasmissione di materiale illecito e comunque lesivo dei diritti di terzi. In caso di
						recesso da parte del Proprietario nessun risarcimento o indennità saranno dovuti
						all’utente.</p>

					<p>&nbsp;</p>

					<p><strong>11. eventuali comunicazioni</strong></p>

					<p>Fermo restando quanto stabilito nel precedente punto 11), qualsiasi comunicazione tra le
						parti dovrà avvenire per iscritto e potrà essere effettuata per posta elettronica attraverso
						la casella <u>info@pedronisocialclub.it</u>.
						Per le comunicazioni aventi riguardo i diritti di cui agli artt. da 15 a 22 GDPR il
						Proprietario mette a disposizione degli utenti l’indirizzo <u>info@toscanoitalia.it</u>.</p>

					<p>&nbsp;</p>

					<p><strong>12. diritti d'autore e proprietà intellettuale</strong></p>

					<p>Salvo ove diversamente indicato, MST o i suoi licenzianti sono titolari dei diritti d’autore
						e degli altri diritti di proprietà industriale ed intellettuale relativi al Sito e al Club.
						L'utente non acquisisce alcun diritto di proprietà su indirizzi e-mail, URL o altro
						identificativo personale a lui assegnato o da lui scelto allo scopo di accedere ai servizi
						disponibili nel Sito e nel Club, che restano di proprietà del Proprietario e/o suoi
						eventuali concedenti. I diritti limitati dell'utente riguardanti l'uso di tali
						indirizzi/identificativi mantengono la loro validità solo finché resta valida la
						registrazione/l’account per l'accesso al Club o ai rispettivi servizi. L'utilizzo o la copia
						non autorizzata dei contenuti del Club e del Sito, ivi incluse le presenti Condizioni, o un
						uso contrario alle presenti disposizioni può comportare la violazione di diritti d'autore,
						di diritti di proprietà industriale ed intellettuale o altri diritti, anche di terzi, nonché
						di disposizioni di legge civile o penale.</p>

					<p>Il Proprietario si riserva il diritto di intraprendere ogni necessaria ed opportuna
						iniziativa legale nel caso di utilizzo o copia non autorizzata del Sito e del Club e di
						violazione delle presenti Condizioni.</p>

					<p>&nbsp;</p>

					<p><strong>13. Cessione diritti sui contenuti trasmessi</strong></p>

					<p>L'utente cede e trasferisce al Proprietario, in via definitiva ed a titolo gratuito, la
						titolarità di tutti i diritti di sfruttamento economico e commerciale relativi ai contenuti
						trasmessi, per l'Italia e per tutto il mondo, fatte comunque salve le garanzie prestate
						dall’utente ai sensi e per gli effetti del precedente punto 6).</p>

					<p>L'utente acconsente alla facoltà del Proprietario di cedere detti contenuti ad altre società
						del proprio gruppo o a società terze.</p>

					<p>Ciò comporta per il Proprietario di verificare che i contenuti sottoposti a sfruttamento
						continuino a godere delle garanzie prestate dagli utenti ai sensi e per gli effetti del
						precedente punto 6).</p>

					<p>Resta inteso che, ai sensi di quanto previsto al precedente art. 9 delle presenti Condizioni,
						l’utente è e rimarrà per l’intera durata del presente rapporto il solo responsabile dei
						danni (compresi quelli che possano avere effetti sui contenuti del Sito e del Club)
						derivanti dall’utilizzo o dalla trasmissione e pubblicazione da parte del medesimo di
						contenuti, materiali ed informazioni sul Sito e sul Club e si obbliga a manlevare e tenere
						indenne MST da qualsiasi responsabilità, pretesa risarcitoria di terzi, perdita, danno,
						costo o spesa (ivi incluse le spese legali), avanzati nei confronti di MST in conseguenza,
						occasione o connessione alle suddette attività svolte dall’utente e comunque riferibili
						all’account ad esso riservato.</p>

					<p>&nbsp;</p>

					<p><strong>14. Collegamenti ad altri siti</strong></p>

					<p>I link a siti di terzi presenti sul Sito e sul Club sono inseriti per utilità degli utenti.
						Nel caso di utilizzo di tali link, gli utenti lasceranno il Sito ed il Club. Il Proprietario
						non ha verificato tali siti di terzi e non ha controllo né è responsabile né garantisce gli
						stessi e i relativi contenuti e la loro disponibilità o i risultati che si possono ottenere
						utilizzando i link ai predetti siti. Ogni rischio connesso all’accesso a siti di terzi
						tramite link presenti nel Sito e nel Club è pertanto a carico degli utenti.</p>

					<p>&nbsp;</p>

					<p><strong>15. giurisdizione e foro competente</strong></p>

					<p>Le Condizioni sono regolate dalla legge italiana. Per qualsiasi controversia inerente,
						derivante o comunque connessa alle Condizioni, all'utilizzo del Sito e del Club ed in
						generale dei servizi resi sul Sito e sul Club sarà competente in via esclusiva il Foro di
						Roma, fatto salvo il caso in cui l’utente sia un consumatore, nel qual caso il foro
						competente sarà quello del luogo di residenza o domicilio elettivo del consumatore.</p>

					<p>&nbsp;</p>
				</Block>
			</Page>
		</Popup>

	);

}

// opened={this.state.popupOpened} onPopupClosed={() => this.setState({popupOpened : false})}
