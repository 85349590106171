// @flow

import React, {useEffect, useState} from 'react';
import {Block, Button, Navbar, NavRight, Page, Segmented, Toolbar} from 'framework7-react';

import './QrCodePage.scss';
import {getRouter, isProduction, toastError, toastSuccess} from "../laco/f7agx";
import {config} from "../app/config";
import {forceNumber} from "../laco/validate";
import {logError} from "../laco/general";
import {aggiungiPartecipante, cercaUtentiId} from "../laco/pedroni";


declare var Instascan: any;

type ICamera = {
	id: string,
	name: string
}

let scannerInstance: any = null;

export const QrCodePage = () => {

	console.log('--- QrCodePage Render ---');

	const [cameraList, setCameraList] = useState<Array<ICamera>>([]);

	const [activeCamera, setActiveCamera] = useState<ICamera | null>(null);

	//const [scannerState, setScanner] = useState<any>(null);

	const visibileDebugButton = (!isProduction() && config.debugFirma);


	const startCamera = (camera: ICamera) => {
		console.log('- startCamera', camera.name);
		scannerInstance.stop();
		setActiveCamera(camera);
		scannerInstance.start(camera);
	};


	useEffect(() => {

		console.log('--- QrCodePage Mount ---');

		scannerInstance = new Instascan.Scanner({
			//mirror: false,
			video: document.getElementById('preview')
		});


		scannerInstance.addListener('scan', function (content: any) {
			console.log('SCAN', content);
			doScan(content);
		});


		Instascan.Camera.getCameras().then(function (cameras: any) {

			console.debug('-- cameras', cameras);

			setCameraList(cameras);

			if (cameras.length > 0) {
				// Activate last: usually the front
				startCamera(cameras[cameras.length-1]);
			} else {
				console.error('No cameras found.');
				toastError('Nessuna camera disponibile');
			}
		}).catch(function (e: any) {
			console.error('camera', e);
			toastError('Errore inizializziazione camera');
		});


		// UNMOUNT
		return function QrCodePageDestroy() {

			console.log('--- QrCodePage Unmount ---');
			if (scannerInstance) {
				console.log('- releasing scannerState');
				scannerInstance.stop().then(() => {
					console.log('- camera stopped');
					scannerInstance = null;
				}).catch(logError);
			}

		}


	}, []);


	const doScan = (id: string | number) => {
		const uid = forceNumber(id);
		if (uid < 1) {
			toastError('Codice letto non valido: ' + id.toString(), 2000);
			return;
		}

		const utente = cercaUtentiId(uid);

		if (!utente) {
			toastError('ID utente non trovato, riprova.', 2000);
			return;
		}

		aggiungiPartecipante(utente);
		toastSuccess('Confermo utente: ' + utente.nominativo, 3000);

		getRouter().back();
	};

	return (
		<Page>
			<Navbar title="QrCode" backLink="Back">
				{visibileDebugButton &&
				<NavRight>
					<Button onClick={() => {
						doScan('30')
					}}>Test Scan!</Button> &nbsp;
				</NavRight>
				}
			</Navbar>
			<Toolbar top>
				<Block>
					<Segmented raised tag="p">
						{cameraList.map(camera => (
							<Button
								active={activeCamera!==null && activeCamera.id===camera.id}
								key={camera.id}
								onClick={()=>{
									startCamera(camera)
								}}
							>{camera.name}</Button>
						))}
					</Segmented>
				</Block>
			</Toolbar>

			<video id="preview"/>
		</Page>
	);
};
