// @flow

//import {Store} from 'laco';
import {Storage} from 'badpenguin-cordova-native';
import {setRouter, webclient} from './f7agx';
import {logError} from './general';
import axios from 'axios';
import {PedroniStore, PedroniStoreLoadAllFromDevice} from './pedroni';
import {Router} from "framework7/modules/router/router";
import {config} from "../app/config";


let isAuth: number = -1;
let isReady: boolean = false;
let authToken: string = '';


export function getAuth(): number {
	return isAuth;
}


export function setAuth(status: number): void {
	console.debug('- setAuth()', status);
	isAuth = status;
	Storage.set('auth', isAuth).catch(logError);
}


function setToken(token: string): void {
	console.debug('- setToken()');
	authToken = token;
	Storage.set('token', token).catch(logError);
}


// private
async function initAuth(): Promise<void> {
	console.debug('- initAuth()');
	if (isReady) {
		console.debug('- isReady already');
		return;
	}
	let isAuth = await Storage.get('auth', false);
	//console.debug('- isAuth', isAuth);

	let token = await Storage.get('token', null);
	//console.debug('- token', token?'ok':'failed');

	if (isAuth > 0 && isAuth !== false && token) {
		// TODO: ping Token
		console.log('- restore Auth', isAuth);
		setToken(token);
		setAuth(isAuth);
	} else {
		setAuth(-1);
		setToken('');
	}
	isReady = true;
	return;
}


export async function checkAuth(to: Router.Route, from: Router.Route, resolve: Function, reject: Function): Promise<void> {
	console.debug('checkAuth(...)');
	if (!isReady) {
		console.debug('- not ready, lets init ...');
		await initAuth();
	}

	//console.log('? isAuth', isAuth);
	//console.log('? authToken', authToken);

	if (isAuth && authToken) {
		console.log('[checkAuth] OK', from.path + ' -> ' + to.path);
		resolve();
		return;
	}
	console.warn('[checkAuth] FAIL', from.path + ' -> ' + to.path);
	reject();
	setRouter('/login/', {reloadAll:true});

	/*
	//clearPreviousHistory?: boolean
	 */
	//const router: Router.Router = this; router.navigate('/login/');
	//return true;
}


export async function doAuth(username: string, password: string): Promise<boolean> {

	const res = await webclient(config.endpoint+'/ajax/ihostess/login/', {
		username,
		password
	}, 'Verifica credenziali in corso...', {debug: true});
	if (res === false) {
		console.warn('doAuth failed');
		setAuth(-1);
		return false;
	}

	console.debug('doAuth success');
	setAuth(res.uid);

	return true;
}


axios.interceptors.response.use(res => {
	if (res.headers.hasOwnProperty('x-jwt')) {
		//console.log('-- interceptor x-jwt header', res.headers['x-jwt']);
		setToken(res.headers['x-jwt']);
		return res;
	}
	if (res.data && res.data.hasOwnProperty('token')) {
		//console.log('-- interceptor x-jwt data', res.data.token);
		setToken(res.data.token);
		return res;
	}
	return res;
}, error => Promise.reject(error));


axios.interceptors.request.use(function (config) {
	//console.log('-- axios.interceptors.request.use');
	if (authToken) {
		//console.log('-- interceptor add x-jwt', authToken);
		config.headers['x-jwt'] = authToken;
	}
	return config;
});


export async function checkFirmaAuth(to: Router.Route, from: Router.Route, resolve: Function, reject: Function): Promise<boolean> {

	console.debug('checkFirmaAuth(...)');
	if (!isReady) {
		console.debug('- not ready, lets init ...');
		await initAuth();
	}

	if (!isAuth || !authToken) {
		console.warn('[checkFirmaAuth] FAIL', from.path + ' -> ' + to.path);
		reject();

		//const router = this; router.navigate('/login/');
		setRouter('/login/', {reloadAll:true});
		return false;
	}

	const pedroniState = PedroniStore.get();
	if (!pedroniState.ready) {
		console.debug('- pedroniState not ready, lets init ...');
		await PedroniStoreLoadAllFromDevice();
	}

	const disallowFirma: boolean = pedroniState.eventoSelezionatoId === null || pedroniState.numeroEventiInElenco === 0 || pedroniState.numeroCittaInElenco === 0;
	const notAllowed: boolean = disallowFirma || !pedroniState.ready;
	if (notAllowed) {
		if (disallowFirma) {
			console.warn('[checkFirmaAuth] FAIL disallowFirma');
		} else if (!pedroniState.ready) {
			console.warn('[checkFirmaAuth] FAIL pedroniState not ready');
		} else {
			console.error('[checkFirmaAuth] FAIL unknown');
		}

		reject();

		//const router = this; router.navigate('/');
		setRouter('/', {reloadAll:true});
		return false;
	}

	console.log('[checkFirmaAuth] OK', from.path + ' -> ' + to.path);
	resolve(true);
	return true;
}
