// @flow strict

import React, {useEffect} from 'react';
import {
	Block,
	BlockTitle,
	Button,
	List,
	ListItem,
	Navbar,
	Page,
	SwipeoutActions,
	SwipeoutButton,
	Toolbar
} from 'framework7-react';
import {useStore} from 'laco-react';
import {
	cancellaIscritto, cancellaPartecipante,
	inviaDati,
	IPedroniStore,
	PedroniStore,
	setElencoIscritti,
	setElencoPartecipanti
} from '../laco/pedroni';
//import './CodaPage.scss';

const DeleteButton = () => (
	<SwipeoutActions right>
		<SwipeoutButton delete
			text="Elimina"
			confirmTitle="Eliminazione"
			confirmText="Confermi la cancellazione?">Elimina</SwipeoutButton>
	</SwipeoutActions>
);

export const CodaPage = () => {

	console.log('--- CodaPage render() ---');

	const pedroniState: IPedroniStore = useStore(PedroniStore);

	useEffect(() => {
		console.log('=== CodaPage UseEffect === ');
	}, []);


	return (
		<Page>

			<Navbar title={
				"Elenco da inviare - Iscritti: " + pedroniState.numeroIscrittiInCoda.toString() +
				" - Partecipanti: " + pedroniState.numeroPartecipantiInCoda.toString()
			}
				backLink="Back"/>

			<BlockTitle className="block-title-evento">Iscritti</BlockTitle>
			<List>
				{pedroniState.elencoIscritti.map((iscritto, index) => (
					<ListItem key={'iscritto' + iscritto.event_id+'-'+iscritto.email} swipeout onSwipeoutDeleted={()=>cancellaIscritto(iscritto)}
						title={`${iscritto.nome} ${iscritto.cognome} - ${iscritto.citta}`}
					>
						<DeleteButton/>
					</ListItem>
				))}
			</List>
			{pedroniState.numeroIscrittiInCoda===0 && (<p className="flex-row-vcenter-center">Nessun iscritto</p>)}

			<BlockTitle className="block-title-evento">Partecipanti</BlockTitle>
			<List>
				{pedroniState.elencoPartecipanti.map((partecipante, index) => (
					<ListItem key={'partecipante' + partecipante.event_id+'-'+partecipante.utenti_id} swipeout
						onSwipeoutDeleted={()=>cancellaPartecipante(partecipante)}
						title={`${partecipante.nominativo}`}
					>
						<DeleteButton/>
					</ListItem>
				))}
			</List>
			{pedroniState.numeroPartecipantiInCoda===0 && (<p className="flex-row-vcenter-center">Nessun partecipante</p>)}

			<Toolbar tabbar labels position="bottom">
				<div className="flex-row-vcenter-center">
					<Button color="rocket" iconF7="play" raised fill className="display-flex wauto"
						onClick={inviaDati}><span className="flex-grow">INVIA</span></Button>
				</div>
			</Toolbar>

		</Page>
	);

};
