// @flow strict

import React, {useEffect, useRef, useState} from 'react';
import {BlockTitle, Button, List, ListInput, ListItem, Navbar, NavLeft, NavTitle, Page} from 'framework7-react';
import ValidatedForm from "../components/ValidatedForm";
import {getInstance, getRouter, isProduction, toastError, toastSuccess} from "../laco/f7agx";
import {config} from "../app/config";
import {setDomValue} from "../laco/vanilla";
import {aggiungiPartecipante, cercaEmail} from "../laco/pedroni";

export const CercaPage = () => {

	console.log('--- CercaPage Render ---');

	const formRef = useRef<HTMLFormElement>(null!);

	const [isFormValid, setFormValid] = useState<boolean>(false);


	useEffect(() => {
		console.log('--- CercaPage Mount ---');


		if (!isProduction() && config.debugFirma) {
			setDomValue('input[name="email"]', 'antoniogallo.it@gmail.com');
			formRef.current.updateValidity();
		}

		return function CercaPageDestroy() {
			console.log('--- CercaPage Unmount ---');
		}
	}, []);


	const onValidityChange = (v: any) => {
		setFormValid(v);
	};


	const doSubmit = (ev: Event): void => {
		console.log('doSubmit', formRef);
		ev.preventDefault();

		if (!formRef) {
			console.log('no form ref');
			return;
		}
		if (!formRef.current) {
			console.log('no form ref current');
			return;
		}
		let valid: boolean = formRef.current.getValidity();
		let dialog = getInstance().dialog;
		if (!valid) {
			console.error('doSubmit: form invalido');
			dialog.alert("Ci dispiace manca qualche dato");
			return;
		}

		const values = formRef.current.getFormValues();
		console.debug('values', values);

		const utente = cercaEmail(values.email);

		if (!utente) {
			toastError('Email utente non trovata, riprova.');
			//document.querySelector<HTMLInputElement>('input[name=email]')!.focus();
			return;
		}

		aggiungiPartecipante(utente);
		toastSuccess('Confermo utente: '+ utente.nominativo, 3000);

		// RESETTA
		/*
		formRef.current.reset();
		document.querySelector('.page-current .page-content')!.scrollTo({top: 0, left: 0});
		document.querySelector<HTMLInputElement>('input[name=email]')!.focus();
*/
		// TODO: NAVI GATE BACK
		getRouter().back();

	};


	return (
		<Page>
			<Navbar>
				<NavLeft backLink/>
				<NavTitle>Ricerca per email</NavTitle>
			</Navbar>

			<BlockTitle>
				Inserisci la tua email per verificare la tua presenza come socio.
			</BlockTitle>

			<ValidatedForm onValidityChange={onValidityChange}
				// @ts-ignore
				ref={formRef}
			>
				<List noHairlines>

					<ListInput
						label="E-mail"
						name="email"
						type="email"
						placeholder=""
						autocapitalize="off" autocomplete="off"
						validate required
						wrap={false}
						className="maxw300 m-auto p-top2"
					>
					</ListInput>

					<ListItem>
						<div className="flex-row-vcenter-center m-top m-bot2">

							<Button color={isFormValid ? 'lightblue' : 'red'} disabled={!isFormValid} type="submit"
								iconF7="enter_round" raised fill className="display-flex wauto"
								onClick={doSubmit}
							><span
								className="flex-grow">CONFERMA</span></Button>
						</div>
					</ListItem>
				</List>
			</ValidatedForm>

		</Page>
	);

};
