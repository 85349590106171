// @flow

import React, {useState} from 'react';


import {
	BlockFooter,
	Button,
	Icon,
	List,
	ListInput,
	ListItem,
	LoginScreenTitle,
	Page,
	SwipeoutActions,
	SwipeoutButton
} from 'framework7-react';
import {doAuth} from '../laco/auth';
import logo from '../assets/logo-150.png';
import {setRouter, toastSuccess} from '../laco/f7agx';
import './LoginPage.scss';
import {config} from "../app/config";
import {build} from "../metadata.json";
import {useStore} from "laco-react";
import {
	ServiceWorkerAddToHome,
	ServiceWorkerIsAppStandalone,
	ServiceWorkerStore,
	ServiceWorkerUninstall
} from "../laco/service-worker";
import {Storage} from 'badpenguin-cordova-native';


type State = {
	username: string;
	password: string;
}


export const LoginPage = () => {

	const [state, setState] = useState<State>({
		username: '',
		password: '',
	});

	const swState = useStore(ServiceWorkerStore);


	const signIn = async (ev: Event) => {
		console.log('signIn()...');
		ev.preventDefault();

		const isAuth = await doAuth(state.username,state.password);

		console.log('doAuth return:', isAuth);

		if (isAuth) {
			setRouter('/', {reloadAll:true});
		} else {
			setState({
				username: '', password: '',
			});
		}

	};


	const doFormattazione = async () => {
		await Storage.instance().clear();
		ServiceWorkerUninstall();
		toastSuccess('Cancellazione completata');
		setTimeout(()=>{
			window.location.href = '/';
		},1900);
	};


	return (
		<Page noToolbar noNavbar noSwipeback loginScreen>
			<LoginScreenTitle>
				<img id="logo" src={logo} alt="Logo"/>
				<small>{config.BRAND} iHostess - v:{build}</small>
			</LoginScreenTitle>
			<List form onSubmit={signIn}>
				<ListInput
					label="Username"
					type="text"
					name="username"
					placeholder="Nome Utente"
					value={state.username}
					autocomplete="current-username"
					onInput={(e: any) => {
						setState({...state, username: e.target.value});
					}}
					errorMessage="il campo è obbligatorio" required validate minlength={3}

				>
					<Icon f7="person_round" slot="media"/>
				</ListInput>
				<ListInput
					label="Password"
					type="password"
					name="password"
					placeholder="La password di accesso"
					value={state.password}
					autocomplete="current-password"
					onInput={(e) => {
						setState({...state, password: e.target.value});
					}}
					errorMessage="il campo è obbligatorio" required validate minlength={3}
				>
					<Icon f7="lock" slot="media"/>
				</ListInput>
				<ListItem className="m-top">
					<Button type="submit" iconF7="enter_round" raised fill className="color-theme-lightblue display-flex" ><span className="flex-grow">Sign In</span></Button>
					<div slot="media"/>
				</ListItem>
				<BlockFooter className="m-top2">Se sei un utente <b>abilitato</b> ad <i>iHostess</i> puoi accedere qui utilizzando le stesse credenziali del Social Club.</BlockFooter>
				<List>
					{!ServiceWorkerIsAppStandalone() ?
						<ListItem title="Installa su questo device" disabled={!swState.beforeinstallprompt}
							onClick={ServiceWorkerAddToHome}
						>
							<Icon slot="media" f7="cloud_download"/>
						</ListItem>
						:
						<ListItem title="App installata correttamente" disabled={true}>
							<Icon slot="media" f7="cloud_download"/>
						</ListItem>
					}
					<ListItem title="Cancella tutti i dati" swipeout onSwipeoutDeleted={doFormattazione}>
						<Icon slot="media" f7="delete_round"/>
						<SwipeoutActions right>
							<SwipeoutButton delete
								text="Elimina"
								confirmTitle="Eliminazione"
								confirmText="Confermi la cancellazione di tutti i dati, inclusi gli iscritti e partecipanti?">Elimina</SwipeoutButton>
						</SwipeoutActions>
					</ListItem>
				</List>
			</List>
		</Page>
	);

};
// onClick={signIn.bind(this)}
