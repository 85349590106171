// @flow strict

import React, {useRef, useState} from 'react';
import './DataDiNascita.scss';
import {has18Years} from "../laco/date";
import {triggerChangeEvent} from "../laco/vanilla";

type DataDiNascitaProps = {
	name: string;
	title: string;
	required?: boolean;
	className?: string;
	classWrap?: string;
	maggiorenne?: boolean;
	onComplete?: Function;
};

export default (props: DataDiNascitaProps) => {

	const name1 = props.name + '-day';
	const name2 = props.name + '-month';
	const name3 = props.name + '-year';

	const required = props.required ? props.required : false;
	const maggiorenne = props.maggiorenne ? props.maggiorenne : false;
	const onComplete = props.onComplete ? props.onComplete : null;
	const classWrap = props.classWrap ? props.classWrap : '';
	const className = props.className ? props.className : '';

	const parentRef = useRef<HTMLDivElement>(null!);
	const dayRef = useRef<HTMLInputElement>(null!);
	const monthRef = useRef<HTMLInputElement>(null!);
	const yearRef = useRef<HTMLInputElement>(null!);
	const hiddenRef = useRef<HTMLInputElement>(null!);

	const [classError,setClassError] = useState<string>('');

	const setDay = (v: string) => {
		dayRef.current.value = v;
	};

	const setMonth = (v: string) => {
		monthRef.current.value = v;
	};

	const setYear = (v: string) => {
		yearRef.current.value = v;
	};

	const setDate = (v: string) => {

		hiddenRef.current.value = v;

		if (parentRef.current) {
			if (v!=='') {
				setClassError('');
			} else {
				setClassError('item-input-with-error-message item-input-invalid');
			}
		}

		triggerChangeEvent(hiddenRef.current);
	};

	const doChangeDay = (ev: React.ChangeEvent<HTMLInputElement>) => {
		let day: number = parseInt(ev.target.value);
		// Se non valido resetta
		if (isNaN(day) || day < 1) {
			setDay('');
			setDate('');
			return;
		}
		// Se hai sforato taglia
		if (day > 31) {
			setDay(ev.target.value.substr(0, 2));
			setDate('');
			return;
		}
		// accetta
		setDay(day.toString());
		// Valida data
		doValidate();
		// Passa al successivo
		if (day >= 4) {
			monthRef.current.focus();
		}
	};


	const doChangeMonth = (ev: React.ChangeEvent<HTMLInputElement>) => {
		let month: number = parseInt(ev.target.value);
		// Se non valido resetta
		if (isNaN(month) || month < 1) {
			setMonth('');
			setDate('');
			return;
		}
		// Se hai sforato taglia
		if (month > 12) {
			setMonth(ev.target.value.substr(0, 2));
			setDate('');
			return;
		}

		// accetta
		setMonth(month.toString());

		// Valida data
		doValidate();

		// Passa al successivo
		if (month >= 2) {
			yearRef.current.focus();
		}
	};


	const doChangeYear = (ev: React.ChangeEvent<HTMLInputElement>) => {
		let year: number = parseInt(ev.target.value);
		// Se non valido resetta
		if (isNaN(year) || year < 1) {
			setYear('');
			setDate('');
			return;
		}
		// Se hai sforato taglia
		if (year > 2100) {
			setYear('');
			setDate('');
			return;
		}

		// accetta
		setYear(year.toString());

		if (ev.target.value.length < 4) {
			setDate('');
			return;
		}

		if (year<1920) {
			setYear('');
			setDate('');
			return;
		}

		// Valida data
		const valid = doValidate();

		// Passa al successivo
		if (valid && onComplete) {
			onComplete();
		}

	};


	const doValidate = (): boolean => {
		const dd: string = dayRef.current.value;
		const mm: string = monthRef.current.value;
		const yyyy: string = yearRef.current.value;

		if (!dd || !mm || !yyyy) {
			//console.log('- manca una delle date');
			setDate('');
			return false;
		}
		const ds = yyyy + '-' + mm + '-' + dd;
		const da = new Date(ds);

		if (da.getDate()!==parseInt(dd)) {
			console.warn('- data errata', dd, da.getDate());
			setDate('');
			return false;
		}

		// Controllo maggiorenne
		if (maggiorenne) {
			if (!has18Years(da)) {
				console.warn('- minorenne');
				setDate('');
				return false;
			}
		}

		//console.log('VALIDO', ds, da);
		const ds2 = da.toISOString().substr(0, 10);
		setDate(ds2);

		return true;
	};

	return (
		<div className={`item-content item-input ${className} ${classError}`} ref={parentRef}>
			<div className="item-inner">
				<div className="item-title item-label">{props.title}</div>
				<div className={`item-input-wrap ${classWrap}`}>
					<div className="data-di-nascita flex-row-vcenter-center">
						<input type="number" name={name1} required={required}
							onChange={doChangeDay} placeholder="GG" min="1" max="31" step="0"
							ref={dayRef}
						/>
						<span className="sep">/</span>
						<input type="number" name={name2} required={required}
							onChange={doChangeMonth} placeholder="MM" min="1" max="12" step="0"
							ref={monthRef}
						/>
						<span className="sep">/</span>
						<input type="number" name={name3} required={required}
							onChange={doChangeYear} placeholder="YYYY" min="1920" max="2100" step="0"
							ref={yearRef}
						/>
					</div>
					<div className="item-input-error-message">Inserisci una data valida. Devi essere maggiorenne.</div>
				</div>

				{/*
				// @ts-ignore */}
				<input type="hidden" name={props.name} required={required} ref={hiddenRef} />
			</div>
		</div>
	);

}
