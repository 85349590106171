// @flow

const dev = {
	debugFirma: true,
	useServiceWorker: false,
	endpoint: 'https://dev.pedronisocialclub.it'
};

const prod = {
	debugFirma: false,
	useServiceWorker: true,
	endpoint: 'https://www.pedronisocialclub.it'
};

const  extra = (process.env.NODE_ENV === "production") ? prod : dev;

export const config = {
	BRAND: 'Pedroni',
	...extra
};
