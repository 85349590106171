// @flow strict

import React, {useRef} from 'react';

import './PrivacyChoice.scss';
import {useForceUpdate} from "../laco/forceUpdate";

type PrivacyChoiseProps = {
	name: string;
	title: string;
	label1?: string;
	value1?: string;
	label2?: string;
	value2?: string;
	classWrap?: string;
	className?: string;
};

export default (props:PrivacyChoiseProps) => {

	//console.log('- VisualizzaCondizioniPopup render()');
	const props2 = Object.assign({}, props);
	const title = props.title ? props.title : '';
	const name = props.name;
	delete props2.title;
	delete props2.name;
	const value1 = props.value1 ? props.value1 : 1;
	const value2 = props.value2 ? props.value2 : 0;
	const label1 = props.label1 ? props.label1 : 'ACCETTO';
	const label2 = props.hasOwnProperty('label2') ? props.label2 : 'NON ACCETTO';
	delete props2.value1;
	delete props2.value2;
	delete props2.label1;
	delete props2.label2;

	const classWrap = props.classWrap ? props.classWrap : "flex-row-vcenter";
	delete props2.classWrap;

	const parentRef = useRef<HTMLDivElement>(null!);

	const forceUpdate = useForceUpdate();

	const doChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
		console.log('doChange');
		if (ev.target.checked) {
			if (parentRef && parentRef.current) {
				parentRef.current.classList.remove('item-input-with-error-message', 'item-input-invalid');
			} else {
				console.error('parentRef null');
			}
			forceUpdate();
			return;
		}
		console.warn('unchecked');
		forceUpdate();
	};


	/*const doBlur = (ev) => {
		console.log('doBlur', ev);
	};

	const doFocus = (ev) => {
		console.log('doFocus', ev);
	};*/

	return (
		<div className={`item-content item-input ${props.className}`} ref={parentRef}>
			<div className="item-inner">
				<div className="item-title item-label">{title}</div>
				<div className={`item-input-wrap ${classWrap}`}>
					<label className="label-radio">
						<label className="radio">
							{/*
							// @ts-ignore */}
							<input type="radio" name={name} value={value1} validate="validate" required={true}
								onChange={doChange}
							/>
							<i className="icon-radio"/>
						</label>
						<span className="">{label1}</span>
					</label>
					{label2 &&
					<label className="label-radio">
						<label className="radio">
							{/*
							// @ts-ignore */}
							<input type="radio" name={name} value={value2} validate="validate" required={true}
								onChange={doChange}
							/>
							<i className="icon-radio"/>
						</label>
						<span className="">{label2}</span>
					</label>
					}
					<div className="item-input-error-message">Compila questo campo.</div>
				</div>
			</div>
		</div>
	);

}

/*
 item-input-invalid item-input-with-error-message*/
