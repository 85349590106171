// @flow strict

import React from 'react';
import {Block, Link, Navbar, NavRight, Page, Popup} from 'framework7-react';

//import './FirmaPage.scss';


export default () => {

	//console.log('- VisualizzaCondizioniPopup render()');

	return (
		<Popup className="visualizza-informativa-popup" tabletFullscreen={true} backdrop={true} swipeToClose={true}
			closeByBackdropClick={true}>
			<Page>
				<Navbar title="Informativa sui cookies">
					<NavRight>
						<Link popupClose>Chiudi</Link>
					</NavRight>
				</Navbar>
				<Block>

					<p><strong>in materia di protezione dei dati personali ai sensi dell’art. 13
						d.Lgs n. 196/2003</strong></p>

					<p>&nbsp;</p>

					<p>In osservanza di quanto previsto dagli artt. 13 e 122 del D.lgs. 30 giugno 2003, nonché da
						quanto previsto dal Provvedimento generale del Garante privacy dell’8 maggio 2014, la
						società <strong>Manifatture Sigaro Toscano S.p.A., con sede in Largo G. Toniolo 6 – 00186
							Roma</strong>, titolare del trattamento, informa gli utenti del sito
						internet <u>www.pedronisocialclub.it</u> (di seguito il
						“Sito”) della tipologia di cookie dagli stessi utilizzati e delle finalità perseguite con le
						informazioni acquisite, fornendo altresì le indicazioni per procedere alla
						selezione/deselezione dei singoli cookie.</p>

					<p><strong>COSA SONO I COOKIE E PER QUALI FINALITA’ POSSONO ESSERE UTILIZZATI</strong></p>

					<p>Un "cookie" è un piccolo file di testo creato da alcuni siti web sul computer dell’utente al
						momento in cui questo accede ad un determinato sito, con lo scopo di immagazzinare e
						trasportare informazioni. I cookie sono inviati da un server web (che è il computer sul
						quale è in esecuzione il sito web visitato) al browser dell’utente (Internet Explorer,
						Mozilla Firefox, Google Chrome, ecc.) e memorizzati sul computer di quest’ultimo; vengono,
						quindi, re-inviati al sito web al momento delle visite successive.</p>

					<p>Alcune operazioni non potrebbero essere compiute senza l'uso dei cookie, che, in alcuni casi,
						sono quindi tecnicamente necessari. In altri casi il sito utilizza cookie per facilitare e
						agevolare la navigazione da parte dell’utente o per consentire a questi di usufruire di
						servizi specificamente richiesti.</p>

					<p>I cookie possono rimanere nel sistema anche per lunghi periodi e possono contenere anche un
						codice identificativo unico. Ciò consente ai siti che li utilizzano di tenere traccia della
						navigazione dell'utente all'interno del sito stesso, per finalità statistiche o
						pubblicitarie, per creare cioè un profilo personalizzato dell'utente a partire dalle pagine
						che lo stesso ha visitato e mostrargli e/o inviargli quindi pubblicità mirate (<em>c.d.
							Behavioural Advertising</em>).</p>

					<p><strong>QUALI COOKIE SONO UTILIZZATI E PER QUALI FINALITA’</strong></p>

					<p>Il presente sito utilizza diversi tipi di cookie tecnici, ma non profilanti.</p>

					<p>Il Titolare, seguendo le indicazioni fornite dall’Autorità Garante nel provvedimento generale
						dell’8 maggio 2014, riporta di seguito le specifiche categorie di cookie adoperati, la
						finalità e la conseguenza che deriva dalla loro deselezione:</p>

					<div className="stacktable-item">
						<table className=" uk-table uk-table-divider stacktable small-only">
							<tbody>
							<tr className="">
								<td className="st-key">
									<p><strong>TIPOLOGIA COOKIE</strong></p>
								</td>
								<td className="st-val ">
									<p>Cookie tecnici</p>
								</td>
							</tr>
							<tr className="">
								<td className="st-key">
									<p><strong>FINALITA’</strong></p>
								</td>
								<td className="st-val ">
									<p>Gestione del sito. Consentono il funzionamento e l’esplorazione sicura ed
										efficiente del sito web</p>
								</td>
							</tr>
							<tr className="">
								<td className="st-key">
									<p><strong>TEMPI DI CONSERVAZIONE</strong></p>
								</td>
								<td className="st-val ">
									<p>6 mesi</p>
								</td>
							</tr>
							<tr className="">
								<td className="st-key">
									<p><strong>CONSEGUENZA IN CASO DI DESELEZIONE</strong></p>
								</td>
								<td className="st-val ">
									<p>Si tratta dei cookie necessari per l’utilizzo del sito Web, bloccarli non ne
										permette il funzionamento</p>
								</td>
							</tr>
							</tbody>
						</table>
						<table className=" uk-table uk-table-divider stacktable small-only">
							<tbody>
							<tr className="">
								<td className="st-key">
									<p><strong>TIPOLOGIA COOKIE</strong></p>
								</td>
								<td className="st-val ">
									<p>Cookie analytics</p>
								</td>
							</tr>
							<tr className="">
								<td className="st-key">
									<p><strong>FINALITA’</strong></p>
								</td>
								<td className="st-val ">
									<p>Raccogliere informazioni in forma aggregata sulla navigazione da parte degli
										utenti per ottimizzare l'esperienza di navigazione e i servizi stessi.</p>
								</td>
							</tr>
							<tr className="">
								<td className="st-key">
									<p><strong>TEMPI DI CONSERVAZIONE</strong></p>
								</td>
								<td className="st-val ">
									<p>Stabiliti dalla terza parte</p>
								</td>
							</tr>
							<tr className="">
								<td className="st-key">
									<p><strong>CONSEGUENZA IN CASO DI DESELEZIONE</strong></p>
								</td>
								<td className="st-val ">
									<p>Non sarebbe più possibile per il Titolare acquisire le informazioni
										aggregate</p>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
					<table className="uk-table uk-table-divider stacktable large-only">
						<thead>
						<tr>
							<td>
								<p><strong>TIPOLOGIA COOKIE</strong></p>
							</td>
							<td>
								<p><strong>FINALITA’</strong></p>
							</td>
							<td>
								<p><strong>TEMPI DI CONSERVAZIONE</strong></p>
							</td>
							<td>
								<p><strong>CONSEGUENZA IN CASO DI DESELEZIONE</strong></p>
							</td>
						</tr>
						</thead>
						<tbody>
						<tr>
							<td>
								<p>Cookie tecnici</p>
							</td>
							<td>
								<p>Gestione del sito. Consentono il funzionamento e l’esplorazione sicura ed
									efficiente del sito web</p>
							</td>
							<td>
								<p>6 mesi</p>
							</td>
							<td>
								<p>Si tratta dei cookie necessari per l’utilizzo del sito Web, bloccarli non ne
									permette il funzionamento</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>Cookie analytics</p>
							</td>
							<td>
								<p>Raccogliere informazioni in forma aggregata sulla navigazione da parte degli
									utenti per ottimizzare l'esperienza di navigazione e i servizi stessi.</p>
							</td>
							<td>
								<p>Stabiliti dalla terza parte</p>
							</td>
							<td>
								<p>Non sarebbe più possibile per il Titolare acquisire le informazioni aggregate</p>
							</td>
						</tr>
						</tbody>
					</table>

					<p><br/>
						&nbsp;</p>

					<p><strong>Cookies di terze parti </strong></p>

					<p>Sul presente sito web sono operativi anche cookie di terzi parti, ossia cookie creati da un
						sito web diverso da quello che l’utente sta attualmente visitando.</p>

					<p>In base a quanto previsto dal Provvedimento del Garante privacy dell’8 maggio 2014, il
						Titolare è tenuto, a fornire il link aggiornato alle informative e ai moduli di consenso
						delle terze parti con cui sono stati stipulati appositi accordi per l’installazione di
						cookie tramite il proprio sito.</p>

					<p><strong>Cookie di terze parti</strong></p>

					<p>Visitando un sito web si possono ricevere cookie sia dal sito visitato (“proprietari”), sia
						da siti gestiti da altre organizzazioni (“terze parti”).<br/>
						La gestione delle informazioni raccolte da “terze parti” è disciplinata dalle relative
						informative cui si prega di fare riferimento.<br/>
						Il sito www.pedronisocialclub.it include talune componenti trasmesse da Google
						Analytics, un servizio di analisi del traffico web fornito da Google, Inc. (“Google”).
						Si tratta di cookie di terze parti raccolti e gestiti in modo anonimo per monitorare e
						migliorare le prestazioni del sito ospite (performance cookie).</p>

					<p><strong>Google Analytics</strong> utilizza i "cookie" per raccogliere e analizzare in forma
						anonima le informazioni sui comportamenti di utilizzo del sito web www. pedronisocialclub.it
						(compreso l'indirizzo IP dell'utente). Tali informazioni vengono raccolte da Google
						Analytics, che le elabora allo scopo di redigere report per gli operatori di
						www.pedronisocialclub.it riguardanti le attività sul Sito web stesso. Questo Sito non
						utilizza (e non consente a terzi di utilizzare) lo strumento di analisi di Google per
						monitorare o per raccogliere informazioni personali di identificazione. Google non associa
						l'indirizzo IP a nessun altro dato posseduto da Google né cerca di collegare un indirizzo IP
						con l'identità di un utente. Google può anche comunicare queste informazioni a terzi ove ciò
						sia imposto dalla legge o laddove tali terzi trattino le suddette informazioni per conto di
						Google.</p>

					<p>Per ulteriori informazioni, si rinvia al link di seguito
						indicato: <u>https://www.google.it/policies/privacy/partners/</u></p>

					<p>L'utente può disabilitare in modo selettivo l'azione di Google Analytics installando sul
						proprio browser la componente di opt-out fornito da Google.</p>

					<p>Per disabilitare l'azione di Google Analytics, si rinvia al link di seguito
						indicato: <u>https://tools.google.com/dlpage/gaoptout</u></p>

					<p><strong>MODALITA’ DEL TRATTAMENTO</strong></p>

					<p>Il trattamento dei dati è eseguito attraverso supporti informatici e telematici ad opera di
						soggetti esterni alla struttura aziendale appositamente incaricati. I dati sono conservati
						in archivi elettronici e con piena assicurazione delle misure di sicurezza minime previste
						dal legislatore.</p>

					<p><strong>COMUNICAZIONE E DIFFUSIONE</strong></p>

					<p>I dati raccolti non saranno oggetto di divulgazione e di diffusione. La comunicazione a
						terzi, diversi dal Titolare, dai responsabili, interni ma anche esterni alla struttura
						aziendale, e dagli incaricati al trattamento individuati e nominati ex artt. 29 e 30 del
						D.lgs. n. 196/2003 e s.m.i., è prevista ove necessaria per le finalità di cui sopra, a
						soggetti terzi (quali società di fornitura e assistenza informatica che garantiscono il
						corretto funzionamento del portale) impegnate nel corretto e regolare perseguimento delle
						finalità descritte. In ogni caso il trattamento da parte di soggetti terzi avverrà secondo
						correttezza e nel rispetto delle disposizioni di legge vigenti.</p>

					<p><strong>DIRITTI DELL’INTERESSATO</strong></p>

					<p>L’interessato potrà far valere i propri diritti come espressi dagli artt. 7, 8, 9 e 10 del
						D.lgs. 30 giugno 2003 n. 196, rivolgendosi al titolare del trattamento. In particolare,
						secondo l’art. 7 l’interessato potrà ottenere la conferma dell'esistenza o meno di dati
						personali che lo riguardano, anche se non ancora registrati e la loro comunicazione in forma
						intelligibile. L'interessato ha diritto di ottenere l'indicazione: a) dell'origine dei dati
						personali; b) delle finalità e modalità del trattamento; c) della logica applicata in caso
						di trattamento effettuato con l'ausilio di strumenti elettronici; d) degli estremi
						identificativi del titolare, dei responsabili e del rappresentante designato ai sensi
						dell'articolo 5, comma 2; e) dei soggetti o delle categorie di soggetti ai quali i dati
						personali possono essere comunicati o che possono venirne a conoscenza in qualità di
						rappresentante designato nel territorio dello Stato, di responsabili o incaricati.
						L'interessato ha diritto di ottenere: a) l'aggiornamento, la rettificazione ovvero, quando
						vi ha interesse, l'integrazione dei dati; b) la cancellazione, la trasformazione in forma
						anonima o il blocco dei dati trattati in violazione di legge, compresi quelli di cui non è
						necessaria la conservazione in relazione agli scopi per i quali i dati sono stati raccolti o
						successivamente trattati; c) l'attestazione che le operazioni di cui alle lettere a) e b)
						sono state portate a conoscenza, anche per quanto riguarda il loro contenuto, di coloro ai
						quali i dati sono stati comunicati o diffusi, eccettuato il caso in cui tale adempimento si
						rivela impossibile o comporta un impiego di mezzi manifestamente sproporzionato rispetto al
						diritto tutelato. L'interessato ha diritto di opporsi, in tutto o in parte: a) per motivi
						legittimi al trattamento dei dati personali che lo riguardano, ancorché pertinenti allo
						scopo della raccolta; b) al trattamento di dati personali che lo riguardano a fini di invio
						di materiale pubblicitario o di vendita diretta o per il compimento di ricerche di mercato o
						di comunicazione commerciale.</p>

					<p><strong>TITOLARE, RESPONSABILI E COMUNICAZIONE PRIVACY</strong></p>

					<p>Il Titolare è la società<strong> Manifatture Sigaro Toscano S.p.A., con sede in Largo G.
						Toniolo 6 – 00186 Roma</strong>. La lista aggiornata dei responsabili è presente presso la
						sede della società. Per ogni comunicazione ex artt. 7 e ss. del D.lgs. n. 196/2003 e s.m.i.
						il Titolare mette a disposizione l’indirizzo <u>info@toscanoitalia.it</u></p>

					<p><strong>TEMPI DI CONSERVAZIONE</strong></p>

					<p>I dati sono conservati per il tempo indicato nella tabella di cui sopra, nel presente
						documento, e comunque finché non venga espressamente esercitato il diritto di opposizione da
						parte dell’interessato.</p>

					<p><strong>DESELEZIONE E ATTIVAZIONE DEI COOKIE</strong></p>

					<p>Accedendo al Sito, l’utente ha prestato il proprio consenso espresso all’utilizzo dei cookie
						tecnici ì espressamente indicati nel presente documento ed all’istallazione dei cookies di
						terze parti sopra indicate.</p>

					<p>La selezione/deselezione dei singoli cookie potrà avvenire liberamente tramite il proprio
						browser (selezionando il menu impostazioni, cliccando sulle opzioni Internet, aprendo la
						scheda relativa alla privacy e scegliendo il desiderato livello di blocco cookie). Per
						maggiori informazioni consultare i seguenti link: <u>Google
							Chrome</u>, <u>Mozilla
							Firefox</u>, <u>Apple
							Safari</u> and <u>Microsoft
							Windows Explorer</u>.</p>

					<p>Inoltre, si può attivare l’opzione Do Not Track presente nella maggior parte dei browser di
						ultima generazione.<em> </em></p>

					<p>I cookie di terze parti possono essere disabilitati informandosi tramite la privacy policy
						della terza parte, tramite il link sopra indicato, o contattando direttamente la stessa. La
						disabilitazione dei cookie “terze parti” non pregiudica in alcun modo la navigabilità.</p>

					<p>Per ulteriori informazioni sui cookie, anche su come visualizzare quelli che sono stati
						impostati sul dispositivo, su come gestirli ed eliminarli,
						visitare <u>www.allaboutcookies.org</u>.</p>

					<p><strong>È NECESSARIO IL CONSENSO ALL’UTILIZZO DEI COOKIE?</strong></p>

					<p>Non è richiesto il consenso dell’utente in tutti quei casi in cui l'utilizzo di cookie o
						altri dispositivi memorizzati sui terminali degli utenti o dei contraenti serva
						esclusivamente per scopi tecnici oppure risponda a specifiche richieste dell'utente o del
						contraente di un servizio Internet.</p>

					<p><br/>nbsp;</p>

				</Block>
			</Page>
		</Popup>

	);

}

// opened={this.state.popupOpened} onPopupClosed={() => this.setState({popupOpened : false})}
