// @flow

export function getAge(birthDay: Date) {
	const ageDistance = Date.now() - birthDay.getTime();
	const rawAge = new Date(ageDistance);
	return Math.abs(rawAge.getUTCFullYear() - 1970);
}

export function has18Years(birthDay: Date) {
	return getAge(birthDay)>=18;
}
