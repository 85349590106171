
export interface Dizionario {
	[details: string] : string;
}


export function forceNumber(src: number|string, defaultValue: number = -1) {
	let newValue: number = parseInt(src+'');
	if (isNaN(newValue)) {
		return defaultValue;
	}
	return newValue;
}


export function validateEmail(email:string) {
	const re = /^([a-z0-9+_-]+)(.[a-z0-9+_-]+)*@([a-z0-9-]+.)+[a-z]{2,6}$/i;
	return re.test(String(email).toLowerCase());
}
