// @flow

import React, {useEffect} from 'react';
import {App, Statusbar, View} from 'framework7-react';

import {routes} from './routes';
import {version} from '../../package.json';
import {Storage} from 'badpenguin-cordova-native';


import {getInstance} from '../laco/f7agx';
import {logError} from '../laco/general';
import {Framework7Params} from "framework7/components/app/app-class";
import {config} from "./config";
import {build} from "../metadata.json";
import {ServiceWorkerSetOnUpdateAvailable, ServiceWorkerSkipWaiting} from "../laco/service-worker";


// Framework7 parameters here
let f7params: Framework7Params = {
	id: 'it.pedronisocialclub.ihostess', // App bundle ID
	name: 'Pedroni iHostess', // App name
	theme: 'auto', // Automatic theme detection
	// App routes
	routes,
	input: {
		scrollIntoViewOnFocus: true
	},
	view: {
		//preloadPreviousPage: false,
		//pushState: false,
		//stackPages: false
	},
	version,
	dialog: {
		buttonOk: 'OK',
		buttonCancel: 'Annulla'
	}
};

if (config.useServiceWorker) {
	console.log('[SWC] F7 will use serviceWorker');
	f7params['serviceWorker'] = {
		path: './service-worker.js',
		scope: '/',
	};
}


//type Props = {};

export default () => {
//export default class extends React.Component<Props>  {

	console.log('--- App render ---');
	//$f7: any;
	//$f7ready: any;
	//console.log('ServiceWorkerIsAppStandalone', ServiceWorkerIsAppStandalone() );
	document.title = 'iHostess - v:' + build;
	console.log('[APP] build:', build);


	const appUpgrade = () => {
		console.log('[APP] richiesta aggiornamento app');
		let c: boolean = window.confirm("Confermi l'aggiornamento?");
		if (c) {
			getInstance().dialog.preloader('Aggiornamento in corso...');
			ServiceWorkerSkipWaiting();
		}
	};


	useEffect(() => {

		console.log('--- App mount ---');

		//const f7: Framework7 = getInstance();

		ServiceWorkerSetOnUpdateAvailable(appUpgrade);

		async function bootstrap() {
			await Storage.config({
				size: 500 * 1024 * 1024
			});
		}

		bootstrap().catch(logError);

	}, []);


	return (
		<App params={f7params}>
			<Statusbar/>

				<View id="main-view" url="/" main className="safe-areas" pushState={true}/>

		</App>
	);

}
