// @flow strict

import React from 'react';



function getFormValues(f: HTMLFormElement): Array<any> {
	let r: any = {};

	for (let el1  of f.elements ) {
		// @ts-ignore
		const el: HTMLInputElement = el1;
		if (el.type === 'checkbox' || el.type === 'radio') {
			if (!el.checked) {
				if (!r.hasOwnProperty(el.name)) {
					r[el.name] = null;
				}
				continue;
			}
		}
		r[el.name] = el.value;
	}
	return r;
}

type ValidatedFormProps = {
	options?: any;
	onValidityChange?: any;
}

export default class ValidatedForm extends React.Component<ValidatedFormProps> {

	formRef = React.createRef<HTMLFormElement>();
	valid: boolean = false;

	constructor(props:any) {
		super(props);
		console.log('-- constructor');
	}

	componentDidMount() {
		console.log('-- componentDidMount');
		this.updateValidity();
	}


	reset(): void {
		if (this.formRef && this.formRef.current) {
			this.formRef.current.reset();
		} else {
			console.error('form ref does not exists');
		}
		this.updateValidity();
		return;
	}


	render() {
		return (
			<form
				{...this.props.options}
				ref={this.formRef}
				onChange={this.onChange}
				onBlur={this.onChange}
				onInvalid={() => {
					console.debug('onInvalid')
				}}
				onReset={() => {
					console.debug('onReset')
				}}
				onSubmit={() => {
					console.debug('onSubmit')
				}}
			>
				{this.props.children}
			</form>
		);
	}


	onChange = () => {
		//console.log('-- onChange');
		this.updateValidity();
	};


	updateValidity() {
		//console.log('-- updateValidity');
		/*if (v!==this.valid) {
			console.log('-- updateValidity CHANGED', v);
			this.valid = v;
			if (this.props.onValidityChange) {
				this.props.onValidityChange(this.valid, this);
			}
		} else {
			console.log('-- updateValidity UN-CHANGED', v);
		}
		*/
		this.valid = this.getValidity();
		if (this.props.onValidityChange) {
			this.props.onValidityChange(this.valid, this);
		}
	}


	getValidity() {
		//console.log('-- getValidity');
		if (this.formRef && this.formRef.current) {
			const f: HTMLFormElement = this.formRef.current;

			for (let el1 of f.elements) {
				// @ts-ignore
				const el: HTMLInputElement = el1;
				if (el.validity) {
					let s: boolean = el.validity.valid;
					if (!s) {
						//console.log('-- validation failed for ', el.name);
						return false;
					}
					// next
				} else {
					console.error('-- missing validation on ', el.name, el.validity);
					return false;
				}
			}
			return true;
		}
		console.error('* isValidForm noform');
		return false;
	}


	getFormValues(): Array<string> {
		//console.log('-- getFormValues');
		if (this.formRef && this.formRef.current) {
			return getFormValues(this.formRef.current);
		}
		return [];
	}

}
